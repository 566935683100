import { Box, Stack, SxProps } from '@mui/material';

interface Props {
  filePath: string;
  title?: string;
  alt?: string;
  sx?: SxProps;
  height?: string | number;
}

export default function ImagePreview({
  filePath,
  title = 'Preview',
  alt = 'image-preview',
  sx,
  height = '150px',
}: Props) {
  return (
    <>
      {filePath !== '' && filePath !== '#' ? (
        <Box textAlign="center" sx={sx}>
          <Stack alignSelf="left" alignItems="left">
            <img src={filePath} alt={alt} style={{ maxHeight: height, width: '100%' }} />
          </Stack>
        </Box>
      ) : undefined}
    </>
  );
}
