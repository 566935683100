/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  System_Admin: 'System Admin',
  Agency_Admin: 'Agency Admin',
  Developer: 'Developer',
} as const;
