import { createContext, useContext } from 'react';

export interface CartState {
  readonly items: ReadonlyArray<CartItem>;
  readonly billingInfo: BillingInfo;
  readonly paymentMethod: PaymentMethod;
  readonly baseAmount: number;
  readonly fees: number;
  readonly total: number;
  readonly coverProcessingFee: boolean;
  readonly raffleAgencyId: string | null;
}

export interface Cart extends CartState {
  addItem(item: CartItem): void;
  updateItem(index: number, values: CartItem): void;
  removeItem(index: number): void;
  clear(): void;
  setBillingInfo(info: BillingInfo): void;
  setPaymentMethod(method: PaymentMethod): void;
  setCoverProcessingFee(coverProcessingFee: boolean): void;
  setRaffleAgencyId(raffleAgencyId: string): void;
}

export interface CartItem {
  agencyId: string;
  agencyName: string;
  amount: number;
  dedication: Dedication;
}

export interface Dedication {
  dedicationType: DedicationType;
  onBehalfOf?: string;
  recipientEmails?: string;
  senderName?: string;
  message?: string;
}

export enum DedicationType {
  None = 'None',
  Honor = 'In Honor Of',
  Memory = 'In Memory Of',
}

export interface BillingInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  anonymous: boolean;
  recognitionName?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
}

export enum PaymentMethod {
  Stripe = 'stripe',
  PayPal = 'paypal',
}

export const DEFAULT_CART: Readonly<CartState> = {
  items: [],
  billingInfo: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    anonymous: false,
    addressLine1: '',
    city: '',
    state: '',
    zip: '',
  },
  paymentMethod: PaymentMethod.Stripe,
  baseAmount: 0,
  fees: 0,
  total: 0,
  coverProcessingFee: false,
  raffleAgencyId: null,
};

export const CartContext = createContext<Cart>({
  ...DEFAULT_CART,
  addItem: () => {},
  updateItem: () => {},
  removeItem: () => {},
  clear: () => {},
  setBillingInfo: () => {},
  setPaymentMethod: () => {},
  setCoverProcessingFee: () => {},
  setRaffleAgencyId: () => {},
});

export const useCart = () => useContext(CartContext);
