import { ApiClient, DynamicComponentTypes, EntityItem } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface CausesToSupport {
  contentComponents: Array<DynamicComponentTypes>;
}

function getCausesToSupport() {
  return ApiClient.get<EntityItem<CausesToSupport>>('/causes-to-support', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: CausesToSupportQuery,
  useQuery: useCausesToSupportQuery,
  prefetch: prefetchCausesToSupport,
} = createPageQuery({
  queryKey: ['causes-to-support'],
  queryFn: unwrap(getCausesToSupport),
});
