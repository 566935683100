import { ApiClient, BannerComponent, DynamicComponentTypes, EntityItem, SEO } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface ErrorPage {
  bannerComponent?: BannerComponent;
  contentComponents?: Array<DynamicComponentTypes>;
  SEO?: SEO;
}

function getErrorPage() {
  return ApiClient.get<EntityItem<ErrorPage>>('/error-page', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: ErrorPageQuery,
  useQuery: useErrorPageQuery,
  prefetch: prefetchErrorPage,
} = createPageQuery({
  queryKey: ['error-page'],
  queryFn: unwrap(getErrorPage),
});
