import { Page } from './page';

export enum NavigationItemType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Wrapper = 'WRAPPER',
}

export interface NavigationItem {
  id: number;
  title: string;
  menuAttached: boolean;
  order: number;
  path: string;
  externalPath: string | null;
  type: NavigationItemType;
  uiRouterKey: string;
  slug: string;
  external: boolean;
  items: Array<NavigationItem>;
  enabled: boolean;
  footerAttached: boolean;
  sideMenuAttached: boolean;
  related?: RelatedItem<Page, 'api::page.page'>;
}

export type RelatedItem<TEntity, TTypeKey extends string> = {
  __contentType: TTypeKey;
  id: number;
} & TEntity;
