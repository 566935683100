import { Controller, FieldValues, Path, useFormState } from 'react-hook-form';
import { Checkbox, FormHelperText, TextFieldProps } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';

interface Props<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TextFieldProps, 'name'> {
  name: Path<TFieldValues>;
}

export function CheckboxInput<TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>
) {
  const { errors } = useFormState();

  return (
    <Controller
      name={props.name}
      render={({ field: { ref, ...fieldProps } }) => (
        <>
          <Checkbox {...fieldProps} checked={fieldProps.value} />
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={data => <FormHelperText error>{data.message}</FormHelperText>}
          />
        </>
      )}
    />
  );
}
