import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Cart, CartContext, CartItem, CartState, DEFAULT_CART } from './CartContext';
import { cartStorage } from './CartStorageProvider';
import { APP_CONFIG } from '../shared/config';

export const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cartState, setCartState] = useState<CartState>(() => cartStorage.read());

  const cart = useMemo<Cart>(() => {
    cartStorage.write(cartState);

    return {
      ...cartState,

      addItem: (item: CartItem) =>
        setCartState(prev => {
          const items = [...prev.items, item];
          return { ...prev, items, ...calculateCartAmounts(items, prev.coverProcessingFee) };
        }),

      updateItem: (index: number, values: CartItem) =>
        setCartState(prev => {
          const items = prev.items as Array<CartItem>;
          items.splice(index, 1, { ...items[index], ...values });

          return { ...prev, items, ...calculateCartAmounts(items, prev.coverProcessingFee) };
        }),

      removeItem: (index: number) =>
        setCartState(prev => {
          const items = prev.items as Array<CartItem>;
          items.splice(index, 1);
          let nextRafflePick = prev.raffleAgencyId;

          // reset raffleAgencyId to null if the current raffle pick
          // no longer has any donations in the cart.
          if (!items.find(item => item.agencyId === prev.raffleAgencyId)) nextRafflePick = null;

          return {
            ...prev,
            items,
            raffleAgencyId: nextRafflePick,
            ...calculateCartAmounts(items, prev.coverProcessingFee),
          };
        }),

      clear: () => setCartState(DEFAULT_CART),

      setBillingInfo: billingInfo => setCartState(prev => ({ ...prev, billingInfo })),

      setPaymentMethod: paymentMethod => setCartState(prev => ({ ...prev, paymentMethod })),

      setCoverProcessingFee: (coverProcessingFee: boolean) =>
        setCartState(prev => ({
          ...prev,
          coverProcessingFee,
          ...calculateCartAmounts(prev.items, coverProcessingFee),
        })),

      setRaffleAgencyId: raffleAgencyId => setCartState(prev => ({ ...prev, raffleAgencyId })),
    };
  }, [cartState]);

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

function calculateCartAmounts(items: ReadonlyArray<CartItem>, coverProcessingFee: boolean) {
  const baseAmount = items.reduce((acc, x) => acc + x.amount, 0);
  const total = coverProcessingFee
    ? Math.trunc(baseAmount / (1 - APP_CONFIG.Donately.ProcessingFeePercent / 100))
    : baseAmount;

  const fees = total - baseAmount;

  return { baseAmount, fees, total };
}
