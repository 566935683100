import React from 'react';
import { IMaskInput } from 'react-imask';

export interface MaskedInputProps {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

export const PhoneNumberMask = React.forwardRef<HTMLElement, MaskedInputProps>(function MaskedInput(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      mask="(000) 000-0000"
      placeholderChar="#"
      unmask={true}
      overwrite
      {...other}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
    />
  );
});

export const TaxIdMask = React.forwardRef<HTMLElement, MaskedInputProps>(function MaskedInput(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      mask="00-0000000"
      placeholderChar="#"
      overwrite
      {...other}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
    />
  );
});

export const CurrencyMask = React.forwardRef<HTMLElement, MaskedInputProps>(function MaskedInput(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      mask={Number}
      scale={2}
      includeThousandsSeparator={true}
      padFractionalZeros={true}
      normalizeZeros={true}
      radix={'.'}
      placeholderChar="#"
      overwrite
      {...other}
      inputRef={ref}
      onAccept={(value: any) => {
        onChange({ target: { name: props.name, value } });
      }}
    />
  );
});
