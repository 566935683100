import { useMemo } from 'react';
import dayjs from 'dayjs';

import { useNextEvent } from './NextEventContext';
import { getEventDayRange } from './getEventDayRange';

export function useIsEventDay() {
  const nextEvent = useNextEvent();

  const range = useMemo(() => getEventDayRange(nextEvent), [nextEvent]);

  return !!range && dayjs.tz().isBetween(range.start, range.end);
}
