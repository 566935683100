import { Box, Grid, Typography } from '@mui/material';
import { GrapesContent } from '../../api/content';
import { VisualContent } from './VisualContent';

interface Props {
  calloutStatistic: string;
  calloutCopy: string;
  content: GrapesContent;
}

export function NewsImpactStory({ calloutStatistic, calloutCopy, content }: Props) {
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <VisualContent data={content} />
      </Grid>
      <Grid item xs={12} md={4} position="relative">
        <Box
          position={{ xs: 'unset', md: 'absolute' }}
          sx={{
            padding: '1rem',
            backgroundColor: 'primary.main',
            maxWidth: '400px',
            zIndex: '10',
            top: '-5rem',
          }}
        >
          <Typography color="common.white" variant="h1" component="p">
            {calloutStatistic}
          </Typography>
          <Typography color="common.white" variant="h6" component="p">
            {calloutCopy}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
