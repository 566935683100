import { useMemo } from 'react';
import { ApiClient, EntityCollection } from '../api/content';
import { createPageQuery, unwrap } from './factory';
import { BoardMember } from './useBoardMembersQuery';

export interface Board {
  boardMembers: EntityCollection<BoardMember>;
  name: string;
  showPhoto: boolean;
  showTitle: boolean;
}

function getBoardsAndCabinets(boards: string[]) {
  return ApiClient.get<EntityCollection<Board>>('/boards', {
    params: {
      filters: {
        id: {
          $in: boards,
        },
      },
      sort: {
        order: 'asc',
      },
      populate: {
        boardMembers: {
          populate: ['photo'],
        },
      },
    },
  });
}

export function getBoardsAndCabinetsQuery(teams: string[]) {
  return createPageQuery({
    queryKey: ['board-and-cabinet', teams],
    queryFn: unwrap(() => getBoardsAndCabinets(teams)),
  });
}

export function useBoardsAndCabinetsQuery(teams: string[]) {
  const { useQuery } = useMemo(() => getBoardsAndCabinetsQuery(teams), [teams]);

  return useQuery();
}
