import React, { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

const maxRetries = 3;
const statusToNotRetry = [400, 403, 404];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > maxRetries) return false;
        if (isAxiosError(error) && statusToNotRetry.includes(error.response?.status || 0))
          return false;
        return true;
      },
    },
  },
});

export const QueryProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
