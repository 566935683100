import { ApiClient, NavigationItem } from '../api/content';
import { createStaticQuery } from './factory';

const KEY = 'navigation';

function renderNavigation(idOrSlug: number | string) {
  return ApiClient.get<Array<NavigationItem>>(`/navigation/render/${idOrSlug}`, {
    params: { type: 'TREE' },
  });
}

export const {
  query: NavigationQuery,
  useQuery: useNavigationQuery,
  prefetch: prefetchNavigation,
} = createStaticQuery({
  queryKey: [KEY, 'main-navigation'],
  queryFn: async () => {
    const { data } = await renderNavigation('main-navigation');
    return data;
  },
});
