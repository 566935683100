import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box } from '@mui/material';
import { Shell } from './Shell';
import { withBoundary } from '../withBoundary';

export const Root: React.FC = () => {
  return (
    <Box minHeight="100vh">
      <Shell>
        <PrimaryOutlet />
      </Shell>
      <ScrollRestoration />
    </Box>
  );
};

const PrimaryOutlet = withBoundary(Outlet);
