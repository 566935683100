import React, { useRef, useState, useMemo } from 'react';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { Box, Popper, Stack } from '@mui/material';
import { common } from '@mui/material/colors';
import clsx from 'clsx';

import { NavigationItem } from '../../../api/content';
import { Link } from '../../components';

interface Props {
  item: NavigationItem;
}

export const NavItem: React.FC<Props> = ({ item }) => {
  const resolved = useResolvedPath(item.path);
  const match = useMatch({ path: resolved.pathname, end: false });

  const anchorEl = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const menuItems = useMemo(() => item.items.filter(x => x.menuAttached), [item]);

  return (
    <Box
      position="relative"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <Box
        className={clsx({ active: !!match || showMenu })}
        sx={{
          height: '100%',
          '&.active': { backgroundColor: 'primary.main' },
        }}
        ref={anchorEl}
      >
        <Link
          to={item.path}
          px={2}
          color="common.white"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '6rem',
            fontWeight: '400',
            marginBottom: '0',
          }}
          variant="body1"
        >
          {item.title}
        </Link>
      </Box>
      {!!menuItems.length && (
        <Popper
          anchorEl={() => anchorEl.current!}
          open={!!match || showMenu}
          placement="bottom"
          disablePortal
          keepMounted
        >
          <Stack
            direction="row"
            sx={{
              width: '100vw',
              backgroundColor: 'primary.main',
              color: 'common.white',
              padding: '0 10px',
              pointerEvents: 'auto',
              letterSpacing: '0.125rem',
              fontSize: '.75rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                maxWidth: '1700px',
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {menuItems.map(x => (
                <Link
                  key={x.id}
                  to={x.path}
                  sx={{ fontWeight: '400', padding: '1rem 0' }}
                  color={common.white}
                  mx={2}
                >
                  {x.title}
                </Link>
              ))}
            </Box>
          </Stack>
        </Popper>
      )}
    </Box>
  );
};
