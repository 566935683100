import React, { useCallback, useState } from 'react';
import { Button, Container, Drawer, Grid, Stack, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from '../../components';
import { useNavigationStructure } from '../../routing';
import { icons } from '../../icons';

export const AppMenu: React.FC = () => {
  const nav = useNavigationStructure();

  const [open, setOpen] = useState(false);
  const setDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    },
    []
  );

  return (
    <>
      <Button
        onClick={setDrawer(true)}
        sx={{ color: 'common.white', typography: { xs: 'h5', md: 'body1' }, marginBottom: '0' }}
      >
        <FontAwesomeIcon icon={icons.menu} />
        <Typography
          color="common.white"
          mx={2}
          mb={0}
          mt={0}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          Menu
        </Typography>
      </Button>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open}
        onClose={setDrawer(false)}
        onClick={setDrawer(false)}
        onKeyDown={setDrawer(false)}
        sx={{
          boxShadow: 'none',
          width: '100%',
        }}
        elevation={0}
        ModalProps={{ disableScrollLock: true }}
        transitionDuration={400}
      >
        <Container maxWidth="xl">
          <Grid container p={2}>
            <Grid item xs={12} justifyContent="end" display="flex">
              <Button onClick={setDrawer(false)} variant="outlined">
                <Typography variant="body2">Close</Typography>
              </Button>
            </Grid>
            {nav.getSideMenuItems().map(item => (
              <Grid key={item.id} item xs={12} md={6} lg={4}>
                <Stack mb={4}>
                  {item.sideMenuAttached ? (
                    <Link to={item.path} variant="h4">
                      {item.title}
                    </Link>
                  ) : (
                    <Typography variant="h4">{item.title}</Typography>
                  )}
                  {item.items
                    .filter(x => x.enabled && x.sideMenuAttached)
                    .map(sub => (
                      <Link
                        key={sub.id}
                        to={sub.path}
                        sx={{ fontWeight: '400' }}
                        color={common.black}
                        mb={1.5}
                        variant="body1"
                      >
                        {sub.title}
                      </Link>
                    ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Drawer>
    </>
  );
};
