import { useMemo } from 'react';
import {
  ApiClient,
  BannerComponent,
  DynamicComponentTypes,
  EntityCollection,
  PageFooterComponent,
  RelatedFile,
  SEO,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface ArticleTopic {
  topic: string;
  newsArticles: EntityCollection<NewsArticle>;
}

export interface NewsArticle {
  name: string;
  bannerComponent: BannerComponent;
  articleContent: Array<DynamicComponentTypes>;
  articleTopics: EntityCollection<ArticleTopic>;
  flipImage: RelatedFile;
  description: string;
  date: string;
  slug: string;
  pageFooter?: PageFooterComponent;
  SEO: SEO;
}

function getNewsArticles(nameQuery: string, selectedTopics: string[]) {
  return ApiClient.get<EntityCollection<NewsArticle>>('/news-articles', {
    params: {
      filters: {
        name: {
          $containsi: nameQuery,
        },
        articleTopics: {
          topic: {
            $in: selectedTopics,
          },
        },
      },
      populate: ['flipImage', 'articleTopics'],
    },
  });
}

export function getNewsArticlesQuery(nameQuery: string, selectedTopics: string[]) {
  return createPageQuery({
    queryKey: ['news', nameQuery],
    queryFn: unwrap(() => getNewsArticles(nameQuery, selectedTopics)),
  });
}

export function useNewsArticlesQuery(nameQuery: string, selectedTopics: string[]) {
  const { useQuery } = useMemo(
    () => getNewsArticlesQuery(nameQuery, selectedTopics),
    [nameQuery, selectedTopics]
  );

  return useQuery();
}
