import React, { useState } from 'react';
import { Box, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { icons } from '../../shared/icons';
import { AgencyManagementList, AdminDonationsList, UserList } from '../components';
import { ButtonLink, DashboardBanner } from '../../shared/components';
import { FundraisingEventList } from '../components/FundraisingEventList';
import { useListEvents } from '../../api/agency-api';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: ['100%', '100%', '80%'] }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const Dashboard: React.FC = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { data: fundraisingEvents } = useListEvents({ includePastEvents: true });
  return (
    <Box>
      <DashboardBanner
        bannerBGColor="primary.light"
        align="end"
        justify="space-between"
        padding={2}
      >
        <Typography variant="h5" component="h1" sx={{ color: 'common.white' }}>
          Admin Dashboard
        </Typography>
        <ButtonLink
          to="/account/signout"
          variant="outlined"
          sx={{
            marginY: '1rem',
            width: 'fit-content',
          }}
        >
          <FontAwesomeIcon icon={icons.signout} />
          <Typography variant="body2" pl={1}>
            Logout
          </Typography>
        </ButtonLink>
      </DashboardBanner>

      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%' }}>
        <Tabs
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          variant="scrollable"
          scrollButtons
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            label="Agencies"
            {...a11yProps(0)}
            sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
          />
          <Tab
            label="Donations"
            {...a11yProps(1)}
            sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
          />
          <Tab
            label="Users"
            {...a11yProps(2)}
            sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
          />
          <Tab
            label="Events"
            {...a11yProps(3)}
            sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          {fundraisingEvents?.items && (
            <AgencyManagementList fundraisingEvents={fundraisingEvents.items} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AdminDonationsList events={fundraisingEvents?.items ?? []} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserList />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <FundraisingEventList />
        </TabPanel>
      </Stack>
    </Box>
  );
};
