import { createContext, useContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

export interface Unauthenticated {
  authenticated: false;
  user?: CognitoUser | null;
  email?: string;
}

export interface Authenticated {
  authenticated: true;
  user: CognitoUser;
  email: string;
  groups: Array<string>;
  userId: string;
}

export type AuthState = Unauthenticated | Authenticated;
export type AuthStateHandle = AuthState & { update: (state: AuthState) => void };

export const AuthenticationContext = createContext<AuthStateHandle>({
  authenticated: false,
  update: () => {},
});

export const useAuthentication = () => useContext(AuthenticationContext);
