import { useEffect, EffectCallback, DependencyList } from 'react';

export function useDebouncedEffect(effect: EffectCallback, timeout: number, deps?: DependencyList) {
  useEffect(() => {
    let result: ReturnType<EffectCallback>;

    const timeoutId = setTimeout(() => {
      result = effect();
    }, timeout);

    return () => {
      clearTimeout(timeoutId);

      if (result) {
        result();
        result = undefined;
      }
    };
    // eslint-disable-next-line
  }, deps);
}
