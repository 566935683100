import { useEffect, useState } from 'react';

import { AuthStateHandle } from './AuthenticationContext';
import { authService } from './AuthService';

export function useResumeSession(authState: AuthStateHandle) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const session = await authService.resumeSession();
      if (session?.session != null) {
        const attrs = session.session.getAccessToken().decodePayload();

        authState.update({
          authenticated: true,
          user: session.user,
          email: authService.getEmail(session.session),
          groups: attrs['cognito:groups'],
          userId: attrs['sub'],
        });
      } else {
        authState.update({ authenticated: false, user: session?.user });
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return loading;
}
