import { EntityCollection, GrapesContent, File } from '../../api/content';
import { VisualContent } from './VisualContent';
import { styled } from '@mui/material';

import { SwiperSlide, Swiper } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import { useState } from 'react';
import { formatMediaUrl } from '../utils';

interface Props {
  gallery: EntityCollection<File>;
  content: GrapesContent;
}

const Image = styled('img')(({ theme }) => ({
  display: 'inline-block',
  height: 'auto',
  width: '100%',
}));

export function NewsEventRecap({ gallery, content }: Props) {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
  return (
    <>
      <VisualContent data={content} />
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
        slidesPerView={2}
      >
        {gallery.data.map(gallery => {
          return (
            <SwiperSlide key={gallery.id}>
              <Image src={formatMediaUrl(gallery.attributes)} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {gallery.data.map(gallery => {
          return (
            <SwiperSlide key={gallery.id}>
              <Image src={formatMediaUrl(gallery.attributes)} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
