import { useMemo } from 'react';
import { ApiClient, EntityCollection } from '../api/content';
import { createPageQuery, unwrap } from './factory';
import { TeamMember } from './useTeamMembersQuery';

export interface Team {
  teamMembers: EntityCollection<TeamMember>;
  name: string;
  createdAt: string;
  updatedAt: string;
}

function getTeams(teams: string[]) {
  return ApiClient.get<EntityCollection<Team>>('/teams', {
    params: {
      filters: {
        id: {
          $in: teams,
        },
      },
      populate: {
        teamMembers: {
          populate: ['photo', 'reactionPhoto'],
        },
      },
    },
  });
}

export function getTeamsQuery(teams: string[]) {
  return createPageQuery({
    queryKey: ['teams', teams],
    queryFn: unwrap(() => getTeams(teams)),
  });
}

export function useTeamsQuery(teams: string[]) {
  const { useQuery } = useMemo(() => getTeamsQuery(teams), [teams]);

  return useQuery();
}
