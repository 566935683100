import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FormProvider, useForm } from 'react-hook-form';

import {
  DonationsListDonationsDonationList,
  ModelsFundraisingEventModel as FundraisingEvent,
  ModelsOrderByDirection,
  exportDonations,
  exportTransactions,
  useListDonations,
} from '../../api/agency-api';
import { useNextEvent } from '../../shared/events';
import { useDebouncedValue } from '../../shared/utils';
import { DataGridToolbar } from '../../shared/components';
import {
  DonationFilterForm,
  adminColumns,
  filterFields,
  sortFieldMap,
} from '../../agency/components/DonationsListComponents';
import downloadFile from '../../shared/utils/downloadFile';
import { TextInput } from '../../shared/forms';
import { icons } from '../../shared/icons';

interface AdminDonationsListProps {
  events: FundraisingEvent[];
  nextEvent?: FundraisingEvent;
}
export const AdminDonationsList: React.FC<AdminDonationsListProps> = ({
  events,
}: AdminDonationsListProps) => {
  const nextEvent = useNextEvent();
  const form = useForm<DonationFilterForm>({
    defaultValues: {
      eventId: nextEvent?.id ?? events[events.length - 1].id,
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'donationDate', sort: 'asc' }],
    },
  });

  const { search, eventId, minDonationAmt, maxDonationAmt, pagination, orderBy } = form.watch();

  const params = useDebouncedValue(
    () => ({
      search,
      eventId,
      minDonationAmt,
      maxDonationAmt,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [search, eventId, minDonationAmt, maxDonationAmt, pagination, orderBy]
  );

  const { data: donations, isPending } = useListDonations(eventId, params);

  const [gridData, setGridData] = useState<DonationsListDonationsDonationList>();

  // only update the gridData after the server responds so that the list doesn't flicker.
  useEffect(() => {
    if (!isPending) setGridData(donations);
  }, [donations, isPending]);

  const selectedEvent = useMemo(() => events.find(x => x.id === eventId), [eventId, events]);

  const handleDonationExport = useCallback(() => {
    if (!selectedEvent) return;

    downloadFile(
      `${selectedEvent.name} Donations Export.csv`,
      exportDonations(selectedEvent.id, params)
    );
  }, [selectedEvent, params]);

  const handleTransactionExport = useCallback(() => {
    if (!selectedEvent) return;

    downloadFile(
      `${selectedEvent.name} Transactions Export.csv`,
      exportTransactions(selectedEvent.id)
    );
  }, [selectedEvent]);

  return (
    <FormProvider {...form}>
      <DataGrid
        autoHeight
        rows={gridData?.items ?? []}
        rowCount={gridData?.totalCount}
        columns={adminColumns}
        pagination
        paginationMode="server"
        paginationModel={pagination}
        pageSizeOptions={[5, 10, 25, 50]}
        onPaginationModelChange={x => form.setValue('pagination', x)}
        sortingMode="server"
        sortModel={orderBy}
        onSortModelChange={x => form.setValue('orderBy', x)}
        sx={{ width: '100%', maxWidth: '100%', border: '0', display: 'grid' }}
        slots={{
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            headerFilterInputs: (
              <TextInput
                select
                name="eventId"
                label="Event"
                variant="outlined"
                sx={{
                  marginBottom: '0',
                  marginLeft: '.25rem',
                }}
              >
                {events.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextInput>
            ),
            filterFields: filterFields,
            modelName: 'Donations',
            allColumns: true,
            onExport: handleDonationExport,
            secondaryExportProps: {
              modelName: 'Transactions',
              buttonIcon: icons.transactions,
              onExport: handleTransactionExport,
            },
          },
        }}
      />
    </FormProvider>
  );
};
