import Axios from 'axios';

export function formatError(error: unknown) {
  if (Axios.isAxiosError(error)) {
    const data = error.response?.data as any;
    if (data?.errors) {
      return { message: data?.errors.Name ?? error.message };
    }
    return { message: data?.detail ?? error.message, data };
  } else {
    return { message: 'An unknown error has occurred.' };
  }
}
