import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { icons } from '../icons';
import { useDebouncedValue } from '../utils';
import { useState } from 'react';

interface Props {
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ setQuery }: Props) {
  let [value, setValue] = useState('');
  useDebouncedValue(() => setQuery(value), 500, [value]);
  return (
    <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
      <FormControl sx={{ mb: 0, width: '100%' }}>
        <OutlinedInput
          id="outlined-adornment"
          sx={{ marginBottom: '0' }}
          startAdornment={
            <InputAdornment position="start">
              <FontAwesomeIcon icon={icons.search} />
            </InputAdornment>
          }
          onChange={(event: { target: { value: any } }) => {
            setValue(event.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
}
