import React, { useMemo } from 'react';
import { Stack } from '@mui/material';

import { File } from '../../api/content';
import { isFile, formatMediaUrl } from '../utils';

type Props = {
  children?: React.ReactNode;
  bannerImage?: File | React.ReactNode;
  tabletBannerImage?: React.ReactNode;
  mobileBannerImage?: React.ReactNode;
  height?: string;
  tabletHeight?: string;
  mobileHeight?: string;
  align?: string;
  justify?: string;
  padding?: number;
  bannerBGColor?: string;
};

export const DashboardBanner: React.FC<Props> = ({
  children,
  bannerImage,
  tabletBannerImage,
  mobileBannerImage,
  height,
  tabletHeight,
  mobileHeight,
  align,
  justify,
  padding,
  bannerBGColor,
}) => {
  const backgroundImage = useMemo(() => {
    if (!isFile(bannerImage)) {
      return {
        xs: `URL(${mobileBannerImage ?? bannerImage})`,
        sm: `URL(${tabletBannerImage ?? bannerImage})`,
        md: `URL(${bannerImage})`,
      };
    }

    return {
      xs: `URL(${formatMediaUrl(bannerImage, 'small')})`,
      sm: `URL(${formatMediaUrl(bannerImage, 'medium')})`,
      md: `URL(${formatMediaUrl(bannerImage)})`,
    };
  }, [bannerImage, mobileBannerImage, tabletBannerImage]);

  return (
    <Stack
      display="flex"
      alignItems={{ xs: 'start', md: 'end' }}
      justifyContent={justify}
      p={padding}
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      sx={{
        position: 'sticky',
        top: '0px',
        zIndex: '10',
        backgroundImage: backgroundImage,
        backgroundColor: bannerBGColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: { xs: mobileHeight, sm: tabletHeight, lg: height },
        padding: {
          xs: '5.5rem 1rem 1rem 1rem',
          sm: '5.5rem 1rem 1rem 1rem',
          md: '7rem 1rem 1rem 1rem',
        },
        width: '100%',
      }}
    >
      {children}
    </Stack>
  );
};
