import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Slide, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { icons } from '../icons';
import {
  AgencyApprovalStatus,
  ModelsFundraisingEventModel,
  getGetApprovalStatusQueryKey,
  useApplyForEvent,
} from '../../api/agency-api';
import { formatError } from '../utils/formatError';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

interface SubmissionAlertProps {
  agencyId: string;
  event: ModelsFundraisingEventModel | null;
  approvalStatus?: AgencyApprovalStatus;
}
export const EventSubmissionAlert: React.FC<SubmissionAlertProps> = ({
  agencyId,
  event,
  approvalStatus,
}: SubmissionAlertProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: applyForEvent } = useApplyForEvent({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Update successful', { variant: 'success' });
        queryClient.invalidateQueries({
          queryKey: getGetApprovalStatusQueryKey(agencyId, event?.id ?? ''),
        });
        setOpen(false);
      },
      onError: (error: Error) => {
        const { message } = formatError(error);
        enqueueSnackbar(`${message}`, { variant: 'error' });
      },
    },
  });
  useEffect(() => {
    if (approvalStatus && event?.registrationEnabled) {
      setOpen(
        approvalStatus !== AgencyApprovalStatus.Approved &&
          approvalStatus !== AgencyApprovalStatus.Submitted
      );
    }
  }, [event, event?.registrationEnabled, approvalStatus, setOpen]);
  return (
    <Stack display="fit-content" width="100%">
      <Slide in={open} mountOnEnter unmountOnExit>
        <Alert
          action={
            <Stack direction="row" alignItems="top" alignContent="end">
              <Button
                sx={{ ml: 2 }}
                onClick={() => applyForEvent({ agencyId: agencyId, eventId: event?.id ?? '' })}
              >
                Apply
              </Button>
              <Button onClick={() => setOpen(false)}>
                <FontAwesomeIcon fixedWidth icon={icons.close} />
              </Button>
            </Stack>
          }
          severity={getAlertSeverity(approvalStatus ?? AgencyApprovalStatus.Not_Submitted)}
          sx={{ padding: '.25rem .5rem', borderRadius: '0', width: '100%', textAlign: 'center' }}
          translate="yes"
        >
          {getAlertMessage(approvalStatus!)} "{event?.name}"
        </Alert>
      </Slide>
    </Stack>
  );
};

function getAlertMessage(status: AgencyApprovalStatus) {
  switch (status) {
    case AgencyApprovalStatus.Not_Submitted:
      return 'Register For Event';
    case AgencyApprovalStatus.Rejected:
      return "Please review your agency's information and resubmit for the event";
    case AgencyApprovalStatus.Under_Review:
      return 'Your submission is under review for';
    default:
      return '';
  }
}

function getAlertSeverity(status: AgencyApprovalStatus) {
  switch (status) {
    case AgencyApprovalStatus.Not_Submitted:
      return 'success';
    case AgencyApprovalStatus.Rejected:
      return 'error';
    default:
      return 'warning';
  }
}
