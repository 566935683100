import { Stack, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingImage from '../assets/logos/uw-loading-icon.svg';
import { Image } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from './icons';

function DefaultErrorFallback() {
  return (
    <Stack
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ '& svg': { fontSize: '8rem', color: 'error.main', marginBottom: '1rem' } }}
    >
      <FontAwesomeIcon icon={icons.caution} />
      <Typography>Oops. Something went wrong. Please try again shortly.</Typography>
    </Stack>
  );
}

export function withErrorBoundary<TProps extends object>(
  Component: React.ComponentType<TProps>,
  fallback: React.ReactElement = <DefaultErrorFallback />
): React.ComponentType<TProps> {
  return props => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  );
}

function DefaultSuspenseFallback() {
  return (
    <Stack height="100vh" width="100%" justifyContent="center" alignItems="center">
      <Image src={LoadingImage} sx={{ width: '128px', height: '128px', marginBottom: '1rem' }} />
      <Typography>Loading Content...</Typography>
    </Stack>
  );
}

export function withSuspenseBoundary<TProps extends object>(
  Component: React.ComponentType<TProps>,
  fallback: React.ReactElement = <DefaultSuspenseFallback />
): React.ComponentType<TProps> {
  return props => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
}

type BoundaryOptions = {
  suspenseFallback?: React.ReactElement;
  errorFallback?: React.ReactElement;
};

export function withBoundary<TProps extends object>(
  Component: React.ComponentType<TProps>,
  { suspenseFallback, errorFallback }: BoundaryOptions = {}
) {
  const SuspenseBoundary = withSuspenseBoundary(Component, suspenseFallback);
  const ErrorBoundary = withErrorBoundary(SuspenseBoundary, errorFallback);

  return ErrorBoundary;
}
