import { ApiClient, EntityCollection, RelatedEntity, File } from '../api/content';
import { createStaticQuery, unwrap } from './factory';

export interface FooterSocialLink {
  name: string;
  link: string;
  icon: RelatedEntity<File>;
}

function getPageFooterSocials() {
  return ApiClient.get<EntityCollection<FooterSocialLink>>('/footer-social-links', {
    params: { populate: ['icon'] },
  });
}

export const {
  query: PageFooterSocialsQuery,
  useQuery: usePageFooterSocialsQuery,
  prefetch: prefetchPageFooterSocials,
} = createStaticQuery({
  queryKey: ['page-footer-socials'],
  queryFn: unwrap(getPageFooterSocials),
});
