import axios from 'axios';
import qs from 'qs';

import { APP_CONFIG } from '../../shared/config';

export const ApiClient = axios.create({
  baseURL: APP_CONFIG.Strapi.BaseUrl,
  paramsSerializer: {
    serialize: (params: any) => qs.stringify(params),
  },
});
