/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type AgencyQuerySortProperty =
  (typeof AgencyQuerySortProperty)[keyof typeof AgencyQuerySortProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyQuerySortProperty = {
  Name: 'Name',
  LegalName: 'LegalName',
  TaxId: 'TaxId',
} as const;
