import {
  ApiClient,
  EntityCollection,
  EntityItem,
  Page,
  PageFooterComponent,
  RelatedFile,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface JoinOurTeam extends Page {
  careers: EntityCollection<{
    title: string;
    jobPdf: RelatedFile;
  }>;
  accolades: Array<{
    name: string;
    logo: RelatedFile;
  }>;
  pageFooter?: PageFooterComponent;
}

function getJoinOurTeam() {
  return ApiClient.get<EntityItem<JoinOurTeam>>('/join-our-team', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: JoinOurTeamQuery,
  useQuery: useJoinOurTeamQuery,
  prefetch: prefetchJoinOurTeam,
} = createPageQuery({
  queryKey: ['join-our-team'],
  queryFn: unwrap(getJoinOurTeam),
});
