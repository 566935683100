import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  feedId: string;
}

export const JuicerFeed: React.FC<Props> = ({ feedId }) => {
  return (
    <>
      <Helmet>
        <script src="https://assets.juicer.io/embed.js" type="text/javascript"></script>
        <link
          href="https://assets.juicer.io/embed.css"
          media="all"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <div className="juicer-feed" data-feed-id={feedId}></div>
    </>
  );
};
