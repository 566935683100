import { FormControlLabel, Grid, Typography } from '@mui/material';
import { TextInput } from '../../shared/forms';
import { CheckboxInput } from '../../shared/forms/fields/CheckboxInput';

export default function AgencyAccounting() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" color="primary.main">
          Accounting
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput name="bookId" variant="outlined" label="Book Id" />
      </Grid>
      <Grid item xs={12}>
        <TextInput name="andarId" variant="outlined" label="ANDAR #" />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<CheckboxInput name="eftReceived" />}
          name="eftReceived"
          label="EFT Received"
          sx={{
            alignItems: 'start',
            '& .MuiTypography-body1': { fontSize: '1rem', color: 'common.black' },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput name="internalComments" label="Internal Comments" multiline />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="createdDate"
          label="Created Date"
          InputProps={{ readOnly: true }}
          aria-readonly="true"
        />
      </Grid>
    </Grid>
  );
}
