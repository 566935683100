import { ApiClient, EntityItem, Page } from '../api/content';
import { createPageQuery, unwrap } from './factory';

interface WhoWeArePage extends Page {}

function getWhoWeAre() {
  return ApiClient.get<EntityItem<WhoWeArePage>>('/who-we-are', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: WhoWeAreQuery,
  useQuery: useWhoWeAreQuery,
  prefetch: prefetchWhoWeAre,
} = createPageQuery({
  queryKey: ['who-we-are'],
  queryFn: unwrap(getWhoWeAre),
});
