import { styled } from '@mui/material';

export const Logo = styled('img')(({ theme }) => ({
  display: 'inline-block',
  height: 'auto',
  maxWidth: '300px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '700px',
  },
}));

export const SocialMediaLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
  height: 'auto',
  maxWidth: '26px',
  width: '100%',
}));
