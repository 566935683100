import React, { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';

export const FormValidationErrorToast: React.FC = () => {
  const { errors } = useFormState();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      enqueueSnackbar('Some fields are not valid. Please correct any errors and try again.', {
        variant: 'error',
      });
    }
  }, [errors]);
  return null;
};
