import React, { useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { Link } from '../../shared/components';

type Props = {
  title?: string;
  description?: string;
  link: string;
};

export const GlobalSearchResultCard: React.FC<Props> = ({ title, link, description }) => {
  const summary = useMemo(() => {
    if ((description?.length ?? 0) <= 400) return description;

    return `${description!.slice(0, 400)}...`;
  }, [description]);
  return (
    <Link to={link}>
      <Card
        sx={{
          backgroundColor: 'grey.100',
          borderRadius: '0',
          padding: '0px',
          boxShadow: 'none',
          '&:hover': { backgroundColor: 'grey.200' },
        }}
      >
        <CardContent
          sx={{
            padding: '.75rem 1.5rem',
          }}
        >
          <Typography
            variant="h5"
            mb={0}
            p={1}
            color="primary.main"
            sx={{ textDecoration: 'underline' }}
          >
            {title}
          </Typography>
          <Typography variant="body2" p={1}>
            {summary}
          </Typography>
          <Box justifyContent="flex-end" display="flex">
            <Typography variant="body2" color="primary.main" sx={{ textDecoration: 'underline' }}>
              Read More
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};
