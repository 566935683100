import { Button } from '@mui/material';

interface Props {
  onFileSelect(event: React.ChangeEvent<HTMLInputElement>): void;
  accept?: string;
  name?: string;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  label: string;
}

export default function FileUpload({
  onFileSelect,
  accept,
  name,
  variant = 'text',
  size = 'medium',
  label,
}: Props) {
  return (
    <Button size={size} variant={variant} component="label">
      {label}
      <input type="file" name={name} accept={accept} onChange={onFileSelect} hidden />
    </Button>
  );
}
