import React, { useMemo } from 'react';
import { Navigate, To, useLocation } from 'react-router-dom';

import { useGetAgenciesByVanityUrl } from '../../api/agency-api';
import { useNavigationStructure } from './Context';
import { ErrorPage } from './ErrorPage';

interface Props {
  path?: To | null;
}

export const FallbackRedirect: React.FC<Props> = ({ path }) => {
  const nav = useNavigationStructure();
  const location = useLocation();

  const to = useMemo(() => nav.findRedirect(location.pathname), [nav, location]);

  return to ? <Navigate to={to} /> : <AgencyFallbackRedirect />;
};

const AgencyFallbackRedirect: React.FC<Props> = ({ path }) => {
  const location = useLocation();
  const vanityUrl = useMemo(() => location.pathname.replace('/', ''), [location]);

  const { data, isLoading } = useGetAgenciesByVanityUrl(vanityUrl);

  if (isLoading) return null;

  if (data?.agency) return <Navigate to={`/roc-the-day/causes/${vanityUrl}`} />;

  return !!path ? <Navigate to={path} /> : <ErrorPage />;
};
