import { useMemo } from 'react';

import { useNextEvent } from './NextEventContext';
import { useIsAdmin } from '../hooks/authFunctions';

export function useIsEventRegistrationOpen() {
  const isAdmin = useIsAdmin();
  const nextEvent = useNextEvent();

  return useMemo(() => isAdmin || !!nextEvent?.registrationEnabled, [isAdmin, nextEvent]);
}
