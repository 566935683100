// This component is a temporary component until the API is refactored to use the Banner component.
// TODO: Remove this component once the API is updated.

import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import { File, HorizontalPosition, VerticalPosition } from '../../api/content';
import {
  isFile,
  formatMediaUrl,
  getPosition,
  isHorizontalPosition,
  isVerticalPosition,
} from '../utils';

type Props = {
  children?: React.ReactNode;
  bannerImage?: File | React.ReactNode;
  tabletBannerImage?: File | React.ReactNode;
  mobileBannerImage?: File | React.ReactNode;
  height?: string;
  tabletHeight?: string;
  mobileHeight?: string;
  align?: VerticalPosition | string;
  justify?: HorizontalPosition | string;
  padding?: number;
  bannerBGColor?: string;
};

export const BannerPlain: React.FC<Props> = ({
  children,
  bannerImage,
  tabletBannerImage,
  mobileBannerImage,
  height,
  tabletHeight,
  mobileHeight,
  align,
  justify,
  padding,
  bannerBGColor,
}) => {
  const backgroundImage = useMemo(() => {
    const bannerImageURL = isFile(bannerImage) ? formatMediaUrl(bannerImage) : bannerImage;
    const mobileBannerImageURL = isFile(mobileBannerImage)
      ? formatMediaUrl(mobileBannerImage, 'small')
      : mobileBannerImage ?? bannerImageURL;
    const tabletBannerImageURL = isFile(tabletBannerImage)
      ? formatMediaUrl(tabletBannerImage, 'medium')
      : tabletBannerImage ?? bannerImageURL;
    return {
      xs: `URL(${mobileBannerImageURL})`,
      sm: `URL(${tabletBannerImageURL})`,
      md: `URL(${bannerImageURL})`,
    };
  }, [bannerImage, mobileBannerImage, tabletBannerImage]);

  const position = useMemo(() => {
    if (isHorizontalPosition(justify) && isVerticalPosition(align))
      return getPosition({ horizontalPosition: justify, verticalPosition: align });
    return { horizontal: justify, vertical: align };
  }, [align, justify]);
  const bannerHeight = useMemo(() => {
    if (!bannerImage && !tabletBannerImage && !mobileBannerImage) return undefined;

    if (mobileHeight && tabletHeight && height)
      return { xs: mobileHeight, sm: tabletHeight, lg: height };

    return { xs: '100vh', sm: '100vh', lg: '100vh' };
  }, [bannerImage, height, mobileBannerImage, mobileHeight, tabletBannerImage, tabletHeight]);
  return (
    <Box
      display="flex"
      alignItems={position?.vertical}
      justifyContent={position?.horizontal}
      p={padding}
      sx={{
        backgroundImage: backgroundImage,
        backgroundColor: bannerBGColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: bannerHeight,
        minHeight: '700px',
        padding: {
          xs: '5.5rem 1rem 1rem 1rem',
          sm: '5.5rem 1rem 1rem 1rem',
          md: '10.5rem 1rem 1rem 1rem',
          lg: '11.5rem 1rem 1rem 1rem',
        },
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};
