import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { withBoundary } from '../withBoundary';
import { useNavigationStructure } from './Context';
import { NavigationRoute } from './NavigationRoute';
import { FallbackRedirect } from './FallbackRedirect';

const DynamicRoutes: React.FC = () => {
  const nav = useNavigationStructure();
  const items = useMemo(() => nav.getDynamicTopLevelRoutes(), [nav]);

  return (
    <Routes>
      {items.map(x => (
        <Route
          key={x.id}
          path={`${x.path?.split('/').at(-1)}/*`}
          element={<NavigationRoute item={x} />}
        />
      ))}
      <Route path="*" element={<FallbackRedirect />} />
    </Routes>
  );
};

export const DynamicTopLevelRoutes = withBoundary(DynamicRoutes);
