import { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Modal,
  Stack,
  TextField,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AgencyApprovalStatus,
  ModelsFundraisingEventModel as FundraisingEvent,
  useListEvents,
  useUpdateApprovalStatus,
} from '../../api/agency-api';
import { icons } from '../../shared/icons';
import { enqueueSnackbar } from 'notistack';
import { formatError } from '../../shared/utils/formatError';
import { agencyApprovalStatuses } from '../../shared/agencyApprovalStatuses';

interface Props {
  agencyId: string;
  nextEvent: FundraisingEvent;
}

export const AgencyApprovalModal: React.FC<Props> = ({ agencyId, nextEvent }: Props) => {
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const { data: events } = useListEvents();

  const [selectedEvent, setSelectedEvent] = useState(nextEvent.id);
  const [comment, setComment] = useState('');
  const [approvalStatus, setApprovalStatus] = useState<AgencyApprovalStatus>();

  const onClose = useCallback(() => setOpen(false), []);

  const handleEventChange = useCallback((eventId: string) => setSelectedEvent(eventId), []);

  const handleStatusChange = useCallback(
    (status: AgencyApprovalStatus) => setApprovalStatus(status),
    []
  );

  const { mutate } = useUpdateApprovalStatus({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Approval Status Updated', { variant: 'success' });
        queryClient.invalidateQueries({
          predicate: query => String(query.queryKey[0]).includes('/Agency'),
        });
        onClose();
      },
      onError: (err: Error) => {
        const { message } = formatError(err);
        enqueueSnackbar(`${message}`, { variant: 'error' });
      },
    },
  });

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Update Approval Status
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          elevation={4}
          sx={{
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <CardHeader
            title="Set Approval Status"
            action={
              <Button sx={{ ml: 2 }} onClick={onClose}>
                <FontAwesomeIcon fixedWidth icon={icons.close} />
              </Button>
            }
            sx={{ pb: '2px' }}
          />

          <CardContent sx={{ pt: '2px' }}>
            {events && events.items && (
              <Stack spacing={2}>
                <TextField
                  select
                  label="Event"
                  name="eventId"
                  value={selectedEvent ?? ''}
                  onChange={e => handleEventChange(e.target.value)}
                >
                  {events?.items?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name} - {item.eventDate}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  name="approvalStatus"
                  label="Status"
                  value={approvalStatus ?? ''}
                  onChange={e => handleStatusChange(e.target.value as AgencyApprovalStatus)}
                >
                  {agencyApprovalStatuses.map(x => (
                    <MenuItem key={x} value={x.toString()}>
                      {x}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="comment"
                  label="Comment"
                  multiline
                  value={comment ?? ''}
                  onChange={e => setComment(e.target.value)}
                ></TextField>
              </Stack>
            )}
          </CardContent>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() =>
                mutate({
                  data: {
                    eventId: selectedEvent,
                    agencyId: agencyId,
                    approvalStatus: approvalStatus,
                    comment: comment,
                  },
                })
              }
            >
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Stack>
        </Card>
      </Modal>
    </>
  );
};
