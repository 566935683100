import {
  ApiClient,
  BannerComponent,
  EntityCollection,
  GrapesContent,
  PageFooterComponent,
  SEO,
} from '../api/content';
import { getEasternTime } from '../shared/utils/getEasternTime';
import { createPageQuery, unwrap } from './factory';

export interface Event {
  slug: string;
  title: string;
  subtitle: string;
  date: string;
  time: string;
  location: string;
  eventType: string;
  bannerComponent?: BannerComponent;
  content: GrapesContent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
  updatedAt: string;
  publishedAt: string;
}

function getEvents() {
  return ApiClient.get<EntityCollection<Event>>('/events', {
    params: {
      filters: {
        date: {
          $gte: getEasternTime(),
        },
      },
      populate: 'deep',
    },
  });
}

export const {
  query: EventsQuery,
  useQuery: useEventsQuery,
  prefetch: prefetchEvents,
} = createPageQuery({
  queryKey: ['events'],
  queryFn: unwrap<Event>(getEvents),
});
