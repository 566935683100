import React from 'react';
import { Stack } from '@mui/material';

import { usePageQuery } from '../../queries';
import { Banner } from './Banner';
import { DynamicZone } from './DynamicZone';
import { Meta } from './Meta';

interface Props {
  id: number;
}

export const ContentPage: React.FC<Props> = ({ id }) => {
  const { data } = usePageQuery(id);

  return (
    <Stack>
      {data.attributes.SEO && (
        <Meta {...data.attributes.SEO} metaImage={data.attributes.SEO.metaImage.data.attributes} />
      )}
      {data.attributes.bannerComponent && (
        <Banner bannerComponent={data.attributes.bannerComponent} height="100vh" padding={1} />
      )}
      {data.attributes.contentComponents && (
        <DynamicZone key={id} components={data.attributes.contentComponents} />
      )}
    </Stack>
  );
};
