import {
  Button,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { TextInput } from '../forms';
import { icons } from '../../shared/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * @param modelName UI friendly name for model displayed.
 * @param headerFilterInputs input(s) intended to display in the grid header next to the search input.
 * @param filterFields Input fields wrapped in a JSX Fragment for filtering grid data.
 * @param enableExport Control whether to display export tooltip for csv export.
 * @param allColumns If true, all data grid columns (visible or hidden) will be included in csv.
 * @param onAdd Function for handling the click of the "Plus" button. If provided, this will add the "Plus" button to the toolbar.
 * @param onExport Function to handle the click of the export button.
 */
export interface DataGridToolbarProps {
  modelName: 'Agency' | 'User' | 'Approvals' | 'Donations';
  headerFilterInputs?: JSX.Element;
  filterFields: JSX.Element;
  secondaryExportProps?: SecondaryExportProps;
  onAdd?(): void;
  onExport?(): void;
}

/**
 * @param modelName UI friendly name for the model.
 * @param buttonIcon IconProp to display in the second export button.
 * @param onExport Function to handle the click of the secondary export button.
 */
export interface SecondaryExportProps {
  modelName: string;
  buttonIcon: IconProp;
  onExport(): void;
}
export const DataGridToolbar: React.FC<DataGridToolbarProps> = ({
  modelName,
  headerFilterInputs,
  filterFields,
  secondaryExportProps,
  onAdd,
  onExport,
}: DataGridToolbarProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <GridToolbarContainer sx={{ p: 0 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: 'grey.200', p: 0 }}
        width="100%"
        padding={0}
      >
        <Stack direction="row" px={1} alignItems="center" sx={{ paddingY: '.25rem' }}>
          <TextInput
            name="search"
            variant="outlined"
            placeholder="Search"
            sx={{ marginBottom: '0' }}
          />
          {headerFilterInputs}
          <Typography
            mb={0}
            ml={1}
            color="grey.600"
            variant="body2"
            display={{ xs: 'none', sm: 'flex' }}
          >
            FILTERS
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ color: 'grey.600' }}
          >
            <FontAwesomeIcon icon={icons.chevronDown} color="grey.100" />
          </ExpandMore>
        </Stack>

        <Stack direction="row" sx={{ backgroundColor: 'grey.600' }}>
          {onAdd && (
            <Tooltip title={`Add ${modelName}`} arrow placement="bottom">
              <Button
                sx={{
                  color: 'common.white',
                  padding: '1rem',
                  borderRight: 'solid thin',
                  borderColor: 'grey.300',
                }}
                onClick={() => onAdd()}
              >
                <FontAwesomeIcon icon={icons.add} size="2x" />
              </Button>
            </Tooltip>
          )}
          {onExport && (
            <Tooltip title={`Export ${modelName} List`} arrow placement="bottom">
              <Button
                sx={{
                  color: 'common.white',
                  padding: '1rem',
                  borderRight: 'solid thin',
                  borderColor: 'primary.main',
                }}
                onClick={() => onExport()}
              >
                <FontAwesomeIcon icon={icons.download} size="2x" />
              </Button>
            </Tooltip>
          )}
          {/* Add export button for secondary export if provided */}
          {secondaryExportProps && secondaryExportProps.onExport && (
            <Tooltip
              title={`Export ${secondaryExportProps.modelName} List`}
              arrow
              placement="bottom"
            >
              <Button
                sx={{
                  color: 'common.white',
                  padding: '1rem',
                  borderRight: 'solid thin',
                  borderColor: 'primary.main',
                }}
                onClick={() => secondaryExportProps.onExport()}
              >
                <FontAwesomeIcon icon={secondaryExportProps.buttonIcon} size="2x" />
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{ backgroundColor: 'grey.100', width: '100%' }}
      >
        <Grid container spacing={2} p={4}>
          {filterFields}
        </Grid>
      </Collapse>
    </GridToolbarContainer>
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: 'common.white',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
