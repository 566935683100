import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { ContentSection, Link } from '../../components';
import { FooterMenu } from './FooterMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../icons';
import { usePageFooterSocialsQuery } from '../../../queries/usePageFooterSocialsQuery';
import { formatMediaUrl } from '../../utils';

const SocialIcon = styled('img')(({ theme }) => ({
  display: 'inline-block',
  padding: '1rem',
  height: 'auto',

  [theme.breakpoints.up('xs')]: {
    maxWidth: '2rem!important',
    padding: '.25rem',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '4rem!important',
    padding: '.5rem',
  },
}));

export const Footer: React.FC = () => {
  const { data: socials } = usePageFooterSocialsQuery();
  let currentYear = new Date().getFullYear();

  return (
    <Stack>
      <ContentSection height="40vh" bannerBGColor="primary.main" padding={3} justify="center">
        <FooterMenu />
      </ContentSection>
      <Box sx={{ backgroundColor: 'common.white' }}>
        <ContentSection
          height="10vh"
          bannerBGColor="common.white"
          justify="center"
          align="start"
          padding={3}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ maxWidth: '1700px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Box>
              {socials?.map(
                social =>
                  social.attributes.icon.data && (
                    <Link key={social.id} to={social.attributes.link}>
                      <SocialIcon
                        src={formatMediaUrl(social.attributes.icon.data.attributes)}
                        alt={social.attributes.name}
                      />
                    </Link>
                  )
              )}
            </Box>
            <Typography variant="body2">
              <FontAwesomeIcon icon={icons.copyright} />
              {currentYear} United Way of Greater Rochester and the Finger Lakes
            </Typography>
          </Stack>
        </ContentSection>
      </Box>
    </Stack>
  );
};
