import { Button, Card, CardContent, CardHeader, MenuItem, Modal, Stack } from '@mui/material';
import { Form, TextInput } from '../../shared/forms';
import { useGetEventById, useUpdateEvent } from '../../api/agency-api';
import * as yup from 'yup';
import { DatePickerField } from '../../shared/forms/fields/DatePickerField';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { formatError } from '../../shared/utils/formatError';

interface EditEventModalProps {
  eventId: string;
  open: boolean;
  onClose(): void;
}

interface EventFields {
  name: string;
  description?: string;
  donatelyCampaignId?: string;
  eventDate: string;
  registrationEnabled: boolean;
  donationAccessEnabled: boolean;
}

export const EditEventModal: React.FC<EditEventModalProps> = ({
  eventId,
  open,
  onClose,
}: EditEventModalProps) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isFetched } = useGetEventById(eventId);
  const { mutate } = useUpdateEvent({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Event Updated', { variant: 'success' });
        queryClient.refetchQueries({
          predicate: q => String(q.queryKey[0]).startsWith('/FundraisingEvent'),
        });
        onClose();
      },
      onError: (err: Error) => {
        const { message } = formatError(err);
        enqueueSnackbar(`${message}`, { variant: 'error' });
      },
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        elevation={4}
        sx={{
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <CardHeader title="Edit Event" sx={{ pb: '2px' }} />
        {isFetched && data && data.model && (
          <Form<EventFields>
            onSubmit={vals => mutate({ id: eventId, data: vals })}
            resolver={yupResolver(schema)}
            defaultValues={{ ...data.model, eventDate: dayjs.tz(data.model.eventDate) } as any}
            method="put"
          >
            <CardContent sx={{ pt: '2px' }}>
              <Stack spacing={2}>
                <TextInput name="name" label="Event Name" variant="outlined" required />
                <TextInput
                  name="donatelyCampaignId"
                  label="Donately Campaign Id"
                  variant="outlined"
                />
                <TextInput name="description" multiline label="Description" variant="outlined" />

                <DatePickerField name="eventDate" label="Event Date" required />
                <TextInput
                  name="registrationEnabled"
                  label="Registration Enabled"
                  select
                  variant="outlined"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextInput>
                <TextInput
                  name="donationAccessEnabled"
                  label="Donation Access Enabled"
                  select
                  variant="outlined"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextInput>
              </Stack>
            </CardContent>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" type="submit">
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </Stack>
          </Form>
        )}
      </Card>
    </Modal>
  );
};

const schema = yup.object({
  name: yup.string().required(),
  donatelyCampaignId: yup.string().optional(),
  description: yup.string().optional(),
  eventDate: yup
    .string()
    .required()
    .transform(val => dayjs(val).tz('America/New_York').format('YYYY-MM-DD')),
  registrationEnabled: yup.bool().default(true),
  donationAccessEnabled: yup.bool().default(false),
});
