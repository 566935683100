import React, { PropsWithChildren, useState } from 'react';
import { To, useMatch, useResolvedPath } from 'react-router-dom';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Link } from '../../components';

interface Props extends PropsWithChildren {
  to: To;
}

export const StaticNavItem: React.FC<Props> = ({ to, children }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box
      position="relative"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <Box
        className={clsx({ active: !!match || showMenu })}
        sx={{
          height: '100%',
          '&.active': { backgroundColor: 'primary.main' },
        }}
      >
        <Link
          to={to}
          px={2}
          color="common.white"
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '6rem',
            fontWeight: '400',
            marginBottom: '0',
          }}
          variant="body1"
        >
          {children}
        </Link>
      </Box>
    </Box>
  );
};
