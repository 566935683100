export interface AppConfig {
  Environment: Environment;
  RTDOnly: boolean;
  Cognito: {
    UserPoolId: string;
    ClientId: string;
  };
  Strapi: {
    BaseUrl: string;
  };
  Search: {
    BaseUrl: string;
    Port: number;
    Protocol: string;
    ApiKey: string;
  };
  Api: {
    BaseUrl: string;
  };
  Donately: {
    BaseUrl: string;
    AccountId: string;
    StripeKey: string;
    ProcessingFeePercent: number;
    CartItemLimit: number;
  };
}

export enum Environment {
  Local = 'Local',
  Development = 'Development',
  Staging = 'Staging',
  Production = 'Production',
}

const runtimeConfig = (globalThis as any)?.['__RUNTIME_CONFIG'] ?? {};

export const APP_CONFIG: AppConfig = {
  Environment: runtimeConfig.ENVIRONMENT ?? Environment.Local,
  RTDOnly: runtimeConfig.RTD_ONLY ?? false,
  Cognito: {
    UserPoolId: runtimeConfig.COGNITO_USERPOOL ?? process.env.REACT_APP_COGNITO_USERPOOL!,
    ClientId: runtimeConfig.COGNITO_CLIENT ?? process.env.REACT_APP_COGNITO_CLIENT!,
  },
  Strapi: {
    BaseUrl: runtimeConfig.STRAPI_API_ENDPOINT ?? process.env.REACT_APP_STRAPI_API_ENDPOINT!,
  },
  Search: {
    BaseUrl: runtimeConfig.SEARCH_HOST ?? process.env.REACT_APP_SEARCH_HOST!,
    Port: Number(runtimeConfig.SEARCH_PORT ?? process.env.REACT_APP_SEARCH_PORT!),
    Protocol: runtimeConfig.SEARCH_PROTOCOL ?? process.env.REACT_APP_SEARCH_PROTOCOL!,
    ApiKey: runtimeConfig.SEARCH_KEY ?? process.env.REACT_APP_SEARCH_KEY!,
  },
  Api: {
    BaseUrl: runtimeConfig.API_BASEURL ?? process.env.REACT_APP_API_BASEURL!,
  },
  Donately: {
    BaseUrl: runtimeConfig.DONATELY_BASEURL ?? process.env.REACT_APP_DONATELY_BASEURL!,
    AccountId: runtimeConfig.DONATELY_ACCOUNT_ID ?? process.env.REACT_APP_DONATELY_ACCOUNT_ID!,
    StripeKey: runtimeConfig.STRIPE_KEY ?? process.env.REACT_APP_STRIPE_KEY!,
    ProcessingFeePercent: Number(
      runtimeConfig.PROCESSING_FEE_PERCENT ?? process.env.REACT_APP_PROCESSING_FEE_PERCENT!
    ),
    CartItemLimit: Number(runtimeConfig.CART_ITEM_LIMIT ?? process.env.REACT_APP_CART_ITEM_LIMIT!),
  },
};
