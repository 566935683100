import React, { PropsWithChildren, useMemo } from 'react';
import { FieldValues, FormProvider, UseFormProps, useForm, SubmitHandler } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object>
  extends PropsWithChildren,
    UseFormProps<TFieldValues, TContext> {
  onSubmit: SubmitHandler<TFieldValues>;
  /**@param method - defaults to 'post' */
  method?: string;
}

export function Form<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>({ onSubmit, method = 'post', children, ...props }: Props<TFieldValues, TContext>) {
  const form = useForm<TFieldValues, TContext>(props);

  const submit = useMemo(() => form.handleSubmit(onSubmit), [form, onSubmit]);

  return (
    <FormProvider {...form}>
      <form method={method} onSubmit={submit}>
        {children}
      </form>
    </FormProvider>
  );
}
