import { GrapesContent } from '../../api/content';
import { VisualContent } from './VisualContent';

export interface PageFooterSectionProps {
  content?: GrapesContent;
}

export function PageFooterSection({ content }: PageFooterSectionProps) {
  return <VisualContent data={content} />;
}
