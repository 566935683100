import { Dispatch } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { Action, ActionType, useAuthFlow } from './useAuthFlow';
import { authService } from './AuthService';
import { EnqueueSnackbar, useSnackbar } from 'notistack';

export enum ForgotPasswordFlowStep {
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ResetPassword',
  ResetPasswordComplete = 'ResetPasswordComplete',
}

type ForgotPasswordStep = ForgotPassword | ResetPassword | ResetPasswordComplete;

export function useForgotPasswordFlow() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useAuthFlow<ForgotPasswordStep>({
    step: ForgotPasswordFlowStep.ForgotPassword,
    onForgotPassword: email => {
      dispatch({ type: ActionType.Pending });

      const user = authService.createUser(email);
      user.forgotPassword({
        onSuccess: () =>
          dispatch({
            type: ActionType.Next,
            state: {
              user,
              ...resetPasswordStep(user, dispatch, enqueueSnackbar),
            },
          }),
        onFailure: error => dispatch({ type: ActionType.Error, error }),
      });
    },
  });

  return state;
}

interface ForgotPassword {
  step: ForgotPasswordFlowStep.ForgotPassword;
  onForgotPassword(email: string): void;
}

interface ResetPassword {
  step: ForgotPasswordFlowStep.ResetPassword;
  onResetPassword(password: string, code: string): void;
}

interface ResetPasswordComplete {
  step: ForgotPasswordFlowStep.ResetPasswordComplete;
}

function resetPasswordStep(
  user: CognitoUser,
  dispatch: Dispatch<Action<ForgotPasswordStep>>,
  enqueueSnackbar: EnqueueSnackbar
): ResetPassword {
  return {
    step: ForgotPasswordFlowStep.ResetPassword,
    onResetPassword: (password, code) => {
      user.confirmPassword(code, password, {
        onSuccess: () => {
          dispatch({
            type: ActionType.Next,
            state: { step: ForgotPasswordFlowStep.ResetPasswordComplete, user },
          });
          enqueueSnackbar('Password Changed Successfully', { variant: 'success' });
        },
        onFailure: error => {
          dispatch({ type: ActionType.Error, error });
          enqueueSnackbar('Password Change Failed', { variant: 'error' });
        },
      });
    },
  };
}
