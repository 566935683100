import React from 'react';
import { Typography, TypographyTypeMap } from '@mui/material';
import CountUp from 'react-countup';

type Props = {
  textVariant: TypographyTypeMap['props']['variant'];
  textAlign?: TypographyTypeMap['props']['textAlign'];
  end: number;
  decimals?: number;
  prefix?: string;
  suffix?: string;
  scrollSpyOnce?: boolean;
  duration?: number;
};

export const CountUpComponent: React.FC<Props> = ({
  textVariant,
  textAlign,
  end,
  decimals,
  prefix,
  suffix,
  scrollSpyOnce,
  duration,
}) => {
  return (
    <Typography variant={textVariant} component="p" textAlign={textAlign}>
      <CountUp
        end={end}
        prefix={prefix}
        suffix={suffix}
        decimals={decimals}
        enableScrollSpy
        scrollSpyDelay={2}
        scrollSpyOnce={scrollSpyOnce}
        duration={duration}
      />
    </Typography>
  );
};
