import { ApiClient, EntityItem, RelatedFile } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface RocLogo {
  rocLogo: RelatedFile;
}

function getRocLogo() {
  return ApiClient.get<EntityItem<RocLogo>>('/roc-logo-banner', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: RocLogoQuery,
  useQuery: useRocLogoQuery,
  prefetch: prefetchRocLogo,
} = createPageQuery({
  queryKey: ['roc-logo-banner'],
  queryFn: unwrap(getRocLogo),
});
