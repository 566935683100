/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type UserQuerySortProperty =
  (typeof UserQuerySortProperty)[keyof typeof UserQuerySortProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserQuerySortProperty = {
  Name: 'Name',
  Id: 'Id',
  Email: 'Email',
} as const;
