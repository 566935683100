import { NavigationItem } from '../../api/content/models/navigation-item';
import { StaticTopLevelPaths } from '../../AppRoutes';
import { Redirect } from '../../queries';

export class NavigationStructure {
  private readonly _itemLookup: Map<number, NavigationItem>;
  private readonly _redirectLookup: Map<string, string>;

  constructor(
    public readonly items: Array<NavigationItem>,
    public readonly redirects: Array<Redirect>
  ) {
    this._itemLookup = new Map<number, NavigationItem>(itemPairs(items));
    this._redirectLookup = new Map<string, string>(
      redirects.map(x => [x.from, x.to] as [string, string])
    );
  }

  getPath(path: string) {
    const parts = path.split('/').filter(x => !!x);

    let firstPath = `/${parts.shift()}`;
    let navItem = this.items.find(x => x.path === firstPath);

    while (parts.length) {
      const key = parts.shift();
      const nextPath = `${navItem?.path}/${key}`;
      navItem = navItem?.items?.find(x => x.path === nextPath);
    }

    return navItem;
  }

  getChildItems(path?: string | null) {
    const items = path != null ? this.getPath(path)?.items : this.items;

    return items ?? [];
  }

  find(id: number) {
    return this._itemLookup.get(id);
  }

  getMenuItems() {
    return this.items.filter(x => x.enabled && x.menuAttached);
  }

  getSideMenuItems() {
    return this.items.filter(
      x => (x.enabled && x.sideMenuAttached) || x.items.some(y => y.enabled && y.sideMenuAttached)
    );
  }

  getFooterItems() {
    return this.items.filter(
      x => (x.enabled && x.footerAttached) || x.items.some(y => y.enabled && y.footerAttached)
    );
  }

  getDynamicTopLevelRoutes() {
    return this.items.filter(x => x.enabled && !StaticTopLevelPaths.has(x.path.replace('/', '')));
  }

  findRedirect(path: string) {
    return this._redirectLookup.get(path);
  }
}

function* itemPairs(items: Array<NavigationItem>): Generator<[number, NavigationItem]> {
  for (const item of items) {
    yield [item.id, item];

    if (item.items != null) yield* itemPairs(item.items);
  }
}
