import {
  ApiClient,
  BannerComponent,
  BannerLinkComponent,
  EntityItem,
  GrapesContent,
  RelatedEntity,
  RelatedFile,
  SEO,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface RTDHomePage {
  bannerComponent?: BannerComponent;
  secondaryBanner: RelatedFile;
  secondaryBannerCTA: BannerLinkComponent;
  roc_phase: RelatedEntity<RTDPhase>;
  SEO?: SEO;
}

export interface RTDPhase {
  title: string;
  content: GrapesContent;
  juicerFeedId?: string | null;
}

function getRTDHomePage() {
  return ApiClient.get<EntityItem<RTDHomePage>>('/roc-the-day-home', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: RTDHomePageQuery,
  useQuery: useRTDHomePageQuery,
  prefetch: prefetchRTDHomePage,
} = createPageQuery({
  queryKey: ['roc-the-day-home'],
  queryFn: unwrap(getRTDHomePage),
});
