import React, { useCallback, useState } from 'react';
import { Box, Button, Container, Drawer, Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode;
}

export const FilterDrawer: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const setDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    },
    []
  );

  return (
    <>
      <Button
        onClick={setDrawer(true)}
        sx={{
          color: 'primary.main',
          typography: 'body1',
          marginBottom: '0',
          backgroundColor: 'grey.200',
        }}
      >
        <Typography color="primary.main" mx={2} mb={0} mt={0}>
          Filter
        </Typography>
      </Button>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={setDrawer(false)}
        sx={{
          boxShadow: 'none',
          width: '100%',
          '& .MuiPaper-root': {
            width: { xs: '100%', md: '30%' },
            padding: '2rem',
          },
        }}
        elevation={0}
        ModalProps={{ disableScrollLock: true }}
        transitionDuration={400}
      >
        <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={setDrawer(false)}
            variant="outlined"
            sx={{ width: 'fit-content', alignSelf: 'end' }}
          >
            <Typography variant="body2">Close</Typography>
          </Button>
          <Box px={2}>{children}</Box>
        </Container>
      </Drawer>
    </>
  );
};
