import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, FieldValues, Path } from 'react-hook-form';

interface Props<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TextFieldProps, 'name'> {
  name: Path<TFieldValues>;
  disablePast?: boolean;
}

export function DatePickerField<TFieldValues extends FieldValues = FieldValues>(
  props: Props<TFieldValues>
) {
  return (
    <Controller
      name={props.name}
      render={({ field: { ref, ...fieldProps } }) => {
        return (
          <DatePicker
            inputRef={ref}
            disablePast={props.disablePast ?? false}
            {...fieldProps}
          ></DatePicker>
        );
      }}
    />
  );
}
