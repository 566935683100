/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type AgencyCategory = (typeof AgencyCategory)[keyof typeof AgencyCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyCategory = {
  Animals: 'Animals',
  Arts_and_Culture: 'Arts and Culture',
  Community_Benefit_and_Economic_Development: 'Community Benefit and Economic Development',
  Education: 'Education',
  Health: 'Health',
  Human_Services: 'Human Services',
  'Religion_/_Faith': 'Religion / Faith',
  The_Environment: 'The Environment',
} as const;
