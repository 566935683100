import * as yup from 'yup';

export const newPasswordSchema = yup
  .string()
  .required()
  .min(8)
  .matches(/[a-z]/g, 'Password must contain at least 1 lowercase character.')
  .matches(/[A-Z]/g, 'Password must contain at least 1 uppercase character.')
  .matches(/[0-9]/g, 'Password must contain at least 1 number.')
  .matches(
    /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/g,
    'Password must contain at least 1 special character.'
  );
