import { HorizontalPosition, Positioned, VerticalPosition } from '../../api/content';

export type FlexPosition = 'start' | 'center' | 'end';

export interface Position {
  horizontal: FlexPosition;
  vertical: FlexPosition;
}

export function isHorizontalPosition(value: any): value is HorizontalPosition {
  return value === 'Center' || value === 'Left' || value === 'Right';
}

export function isVerticalPosition(value: any): value is VerticalPosition {
  return value === 'Center' || value === 'Top' || value === 'Bottom';
}

export function getPosition(positioned?: Positioned | null): Position {
  return {
    horizontal: horizontalPosition(positioned),
    vertical: verticalPosition(positioned),
  };
}

const horizontalPosition = (positioned?: Positioned | null): FlexPosition => {
  switch (positioned?.horizontalPosition) {
    case HorizontalPosition.Left:
      return 'start';
    case HorizontalPosition.Center:
      return 'center';
    case HorizontalPosition.Right:
    default:
      return 'end';
  }
};

const verticalPosition = (positioned?: Positioned | null): FlexPosition => {
  switch (positioned?.verticalPosition) {
    case VerticalPosition.Bottom:
      return 'end';
    case VerticalPosition.Center:
      return 'center';
    case VerticalPosition.Top:
    default:
      return 'start';
  }
};
