import { Button, Card, CardContent, CardHeader, Modal, Stack } from '@mui/material';
import { useAddEvent } from '../../api/agency-api';
import { Form, TextInput } from '../../shared/forms';
import { DatePickerField } from '../../shared/forms/fields/DatePickerField';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { formatError } from '../../shared/utils/formatError';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

interface AddEventModalProps {
  open: boolean;
  onClose(): void;
}

interface EventFields {
  name: string;
  description?: string;
  donatelyCampaignId?: string;
  eventDate: string;
}
export const AddEventModal: React.FC<AddEventModalProps> = ({
  open,
  onClose,
}: AddEventModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate } = useAddEvent({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Event Created', { variant: 'success' });
        queryClient.refetchQueries({
          predicate: q => String(q.queryKey[0]).startsWith('/FundraisingEvent'),
        });
        onClose();
      },
      onError: (err: Error) => {
        const { message } = formatError(err);
        enqueueSnackbar(`${message}`, { variant: 'error' });
      },
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        elevation={4}
        sx={{
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <CardHeader title="Add Event" sx={{ pb: '2px' }} />
        <Form<EventFields>
          onSubmit={data => mutate({ data })}
          resolver={yupResolver(schema)}
          defaultValues={{ eventDate: dayjs.tz() as any }}
        >
          <CardContent sx={{ pt: '2px' }}>
            <Stack spacing={2}>
              <TextInput name="name" label="Event Name" variant="outlined" required />
              <TextInput
                name="donatelyCampaignId"
                label="Donately Campaign Id"
                variant="outlined"
              />
              <TextInput name="description" multiline label="Description" variant="outlined" />

              <DatePickerField name="eventDate" label="Event Date" disablePast required />
            </Stack>
          </CardContent>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" type="submit">
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Stack>
        </Form>
      </Card>
    </Modal>
  );
};

const schema = yup.object({
  name: yup.string().required(),
  donatelyCampaignId: yup.string().optional(),
  description: yup.string().optional(),
  eventDate: yup
    .string()
    .required()
    .transform(val => dayjs(val).tz('America/New_York').format('YYYY-MM-DD')),
});
