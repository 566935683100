import { useMemo } from 'react';
import { ApiClient, EntityCollection } from '../api/content';
import { createPageQuery, unwrap } from './factory';
import { LeadershipDevelopmentProgram } from './useLeadershipDevelopmentHomePageQuery';

function getLeadershipPrograms(slug: string) {
  return ApiClient.get<EntityCollection<LeadershipDevelopmentProgram>>(
    `/leadership-development-programs`,
    {
      params: {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        populate: 'deep',
      },
    }
  );
}

export function getLeadershipProgramsQuery(slug: string) {
  return createPageQuery({
    queryKey: ['program', slug],
    queryFn: unwrap(() => getLeadershipPrograms(slug)),
  });
}

export function useLeadershipProgramsQuery(slug: string) {
  const { useQuery } = useMemo(() => getLeadershipProgramsQuery(slug), [slug]);

  return useQuery();
}
