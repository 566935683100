/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type ModelsOrderByDirection =
  (typeof ModelsOrderByDirection)[keyof typeof ModelsOrderByDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModelsOrderByDirection = {
  Ascending: 'Ascending',
  Descending: 'Descending',
} as const;
