import React, { PropsWithChildren } from 'react';

import { useGetNextEvent, getGetNextEventQueryOptions } from '../api/agency-api';
import { NextEventContext } from '../shared/events';
import { queryClient } from './QueryProvider';

queryClient.prefetchQuery(getGetNextEventQueryOptions());

export const NextEventProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // refetch every 5 minutes
  const { data } = useGetNextEvent({ query: { refetchInterval: 5 * 60 * 1000 } });
  return (
    <NextEventContext.Provider value={data?.event ?? null}>{children}</NextEventContext.Provider>
  );
};
