import React, { useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

import { icons } from '../../shared/icons';
import { useDebouncedEffect } from '../../shared/hooks';

export const GlobalSearch: React.FC = () => {
  const { refine } = useSearchBox();
  const [value, setValue] = useState('');

  useDebouncedEffect(
    () => {
      refine(value);
    },
    300,
    [value]
  );

  return (
    <Box display={'flex'} justifyContent={'center'} px={3}>
      <FormControl sx={{ p: 2, width: '100%' }}>
        <OutlinedInput
          id="outlined-adornment"
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <FontAwesomeIcon icon={icons.search} />
            </InputAdornment>
          }
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </FormControl>
    </Box>
  );
};
