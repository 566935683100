/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  FundraisingEventsAddEventForm,
  FundraisingEventsAddEventResult,
  FundraisingEventsGetEventByIdResult,
  FundraisingEventsGetNextEventResult,
  FundraisingEventsListEventsFundraisingEventList,
  FundraisingEventsUpdateEventForm,
  FundraisingEventsUpdateEventResult,
  ListEventsParams,
} from '.././models';
import { apiRequest } from '.././client';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getEventById = (
  id: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<FundraisingEventsGetEventByIdResult>(
    { url: `/FundraisingEvent/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetEventByIdQueryKey = (id: string) => {
  return [`/FundraisingEvent/${id}`] as const;
};

export const getGetEventByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEventById>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEventByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventById>>> = ({ signal }) =>
    getEventById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEventById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEventByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventById>>>;
export type GetEventByIdQueryError = unknown;

export const useGetEventById = <TData = Awaited<ReturnType<typeof getEventById>>, TError = unknown>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEventByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateEvent = (
  id: string,
  fundraisingEventsUpdateEventForm: FundraisingEventsUpdateEventForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<FundraisingEventsUpdateEventResult>(
    {
      url: `/FundraisingEvent/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: fundraisingEventsUpdateEventForm,
    },
    options
  );
};

export const getUpdateEventMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEvent>>,
    TError,
    { id: string; data: FundraisingEventsUpdateEventForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEvent>>,
  TError,
  { id: string; data: FundraisingEventsUpdateEventForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEvent>>,
    { id: string; data: FundraisingEventsUpdateEventForm }
  > = props => {
    const { id, data } = props ?? {};

    return updateEvent(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEventMutationResult = NonNullable<Awaited<ReturnType<typeof updateEvent>>>;
export type UpdateEventMutationBody = FundraisingEventsUpdateEventForm;
export type UpdateEventMutationError = unknown;

export const useUpdateEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEvent>>,
    TError,
    { id: string; data: FundraisingEventsUpdateEventForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEvent>>,
  TError,
  { id: string; data: FundraisingEventsUpdateEventForm },
  TContext
> => {
  const mutationOptions = getUpdateEventMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteEvent = (id: string, options?: SecondParameter<typeof apiRequest>) => {
  return apiRequest<void>({ url: `/FundraisingEvent/${id}`, method: 'DELETE' }, options);
};

export const getDeleteEventMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEvent>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEvent>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEvent>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteEvent(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEventMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEvent>>>;

export type DeleteEventMutationError = unknown;

export const useDeleteEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEvent>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEvent>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteEventMutationOptions(options);

  return useMutation(mutationOptions);
};
export const addEvent = (
  fundraisingEventsAddEventForm: FundraisingEventsAddEventForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<FundraisingEventsAddEventResult>(
    {
      url: `/FundraisingEvent`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: fundraisingEventsAddEventForm,
    },
    options
  );
};

export const getAddEventMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEvent>>,
    TError,
    { data: FundraisingEventsAddEventForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addEvent>>,
  TError,
  { data: FundraisingEventsAddEventForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addEvent>>,
    { data: FundraisingEventsAddEventForm }
  > = props => {
    const { data } = props ?? {};

    return addEvent(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddEventMutationResult = NonNullable<Awaited<ReturnType<typeof addEvent>>>;
export type AddEventMutationBody = FundraisingEventsAddEventForm;
export type AddEventMutationError = unknown;

export const useAddEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addEvent>>,
    TError,
    { data: FundraisingEventsAddEventForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof addEvent>>,
  TError,
  { data: FundraisingEventsAddEventForm },
  TContext
> => {
  const mutationOptions = getAddEventMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listEvents = (
  params?: ListEventsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<FundraisingEventsListEventsFundraisingEventList>(
    { url: `/FundraisingEvent`, method: 'GET', params, signal },
    options
  );
};

export const getListEventsQueryKey = (params?: ListEventsParams) => {
  return [`/FundraisingEvent`, ...(params ? [params] : [])] as const;
};

export const getListEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof listEvents>>,
  TError = unknown
>(
  params?: ListEventsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvents>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listEvents>>> = ({ signal }) =>
    listEvents(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListEventsQueryResult = NonNullable<Awaited<ReturnType<typeof listEvents>>>;
export type ListEventsQueryError = unknown;

export const useListEvents = <TData = Awaited<ReturnType<typeof listEvents>>, TError = unknown>(
  params?: ListEventsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listEvents>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNextEvent = (
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<FundraisingEventsGetNextEventResult>(
    { url: `/FundraisingEvent/next`, method: 'GET', signal },
    options
  );
};

export const getGetNextEventQueryKey = () => {
  return [`/FundraisingEvent/next`] as const;
};

export const getGetNextEventQueryOptions = <
  TData = Awaited<ReturnType<typeof getNextEvent>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNextEvent>>, TError, TData>>;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNextEventQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNextEvent>>> = ({ signal }) =>
    getNextEvent(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNextEvent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNextEventQueryResult = NonNullable<Awaited<ReturnType<typeof getNextEvent>>>;
export type GetNextEventQueryError = unknown;

export const useGetNextEvent = <
  TData = Awaited<ReturnType<typeof getNextEvent>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNextEvent>>, TError, TData>>;
  request?: SecondParameter<typeof apiRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNextEventQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
