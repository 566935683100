import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useNavigationQuery, useRedirectsQuery } from '../../queries';
import { NavigationStructure } from './NavigationStructure';
import { withBoundary } from '../withBoundary';

const Context = createContext<NavigationStructure>({} as NavigationStructure);

export function useNavigationStructure() {
  return useContext(Context);
}

const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: navigation } = useNavigationQuery();
  const { data: redirects } = useRedirectsQuery();

  const structure = useMemo(
    () => new NavigationStructure(navigation, redirects),
    [navigation, redirects]
  );

  return <Context.Provider value={structure}>{children}</Context.Provider>;
};

export const NavigationStructureProvider = withBoundary(Provider);
