export const uploadFormData = <Body>(body: Body): FormData => {
  const formData = new FormData();

  function append(key: string, value: unknown) {
    if (value === null || value === undefined) return;
    if (Array.isArray(value)) value.forEach(i => append(key, i));
    else if (value instanceof File) formData.append(key, value, value.name);
    else if (value instanceof Blob) formData.append(key, value);
    else if (typeof value === 'string') formData.append(key, value);
    else if (typeof value === 'object')
      Object.entries(value).forEach(entry => append(`${key}.${entry[0]}`, entry[1]));
    else formData.append(key, JSON.stringify(value));
  }

  Object.entries(body as any).forEach(([key, value]) => append(key, value));

  return formData;
};

export default uploadFormData;
