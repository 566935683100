/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type AgencyApprovalStatus = (typeof AgencyApprovalStatus)[keyof typeof AgencyApprovalStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgencyApprovalStatus = {
  Not_Submitted: 'Not Submitted',
  Submitted: 'Submitted',
  Under_Review: 'Under Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
} as const;
