import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { withBoundary } from '../shared/withBoundary';
import { FallbackRedirect } from '../shared/routing';
import { AddUserPage, Dashboard, EditUserPage } from './pages';
import AdminAgencyDetailsPage from './pages/AdminAgencyDetailsPage';

export const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="dashboard" replace />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="users">
        <Route path=":userId" element={<EditUserPage />} />
        <Route path="add" element={<AddUserPage />} />
      </Route>
      <Route path="agencies/:agencyId" element={<AdminAgencyDetailsPage />} />
      <Route path="*" element={<FallbackRedirect />} />
    </Routes>
  );
};

export const Component = withBoundary(AdminRoutes);
