import { useMemo } from 'react';
import { ApiClient, EntityItem, Page } from '../api/content';
import { createPageQuery, unwrap } from './factory';

function getPage(id: number) {
  return ApiClient.get<EntityItem<Page>>(`/pages/${id}`, {
    params: {
      populate: 'deep',
    },
  });
}

export function getPageQuery(id: number) {
  return createPageQuery({
    queryKey: ['page', id],
    queryFn: unwrap(() => getPage(id)),
  });
}

export function usePageQuery(id: number) {
  const { useQuery } = useMemo(() => getPageQuery(id), [id]);

  return useQuery();
}
