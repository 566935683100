import { useErrorPageQuery } from '../../queries';
import { Banner, DynamicZone, Meta } from '../components';

export function ErrorPage() {
  const { data: page } = useErrorPageQuery();
  return (
    <>
      {page.attributes.SEO && (
        <Meta {...page.attributes.SEO} metaImage={page.attributes.SEO.metaImage.data.attributes} />
      )}
      {page.attributes.bannerComponent && (
        <Banner bannerComponent={page.attributes.bannerComponent} />
      )}
      {page.attributes.contentComponents && (
        <DynamicZone components={page.attributes.contentComponents} />
      )}
    </>
  );
}
