import {
  ApiClient,
  BannerComponent,
  DynamicComponentTypes,
  EntityItem,
  PageFooterComponent,
  SEO,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

interface BoardAndCabinetPage {
  bannerComponent?: BannerComponent;
  contentComponents?: Array<DynamicComponentTypes>;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

function getBoardAndCabinetPage() {
  return ApiClient.get<EntityItem<BoardAndCabinetPage>>('/board-and-cabinet', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: BoardAndCabinetPageQuery,
  useQuery: useBoardAndCabinetPageQuery,
  prefetch: prefetchBoardAndCabinetPage,
} = createPageQuery({
  queryKey: ['board-and-cabinet'],
  queryFn: unwrap(getBoardAndCabinetPage),
});
