import { ApiClient, EntityItem, Page, PageFooterComponent } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface OurTeamPage extends Page {
  pageFooter?: PageFooterComponent;
}

function getOurTeamPage() {
  return ApiClient.get<EntityItem<OurTeamPage>>('/our-team', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: OurTeamPageQuery,
  useQuery: useOurTeamPageQuery,
  prefetch: prefetchOurTeamPage,
} = createPageQuery({
  queryKey: ['our-team'],
  queryFn: unwrap(getOurTeamPage),
});
