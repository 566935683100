import React from 'react';

import { NavigationItem } from '../../api/content/models/navigation-item';
import { ContentPage } from '../components/ContentPage';

interface Props {
  item: NavigationItem;
}

export const GenericContent: React.FC<Props> = ({ item }) => {
  switch (item.related?.__contentType) {
    case 'api::page.page':
      return <ContentPage id={item.related.id} />;
    default:
      return null;
  }
};
