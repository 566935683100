import React, { useMemo } from 'react';

import { File, SEO } from '../../api/content';
import { Helmet } from 'react-helmet';
import { useImageUrl } from '../hooks';

type Props = Omit<SEO, 'metaImage'> & { metaImage: File | string };

export const Meta: React.FC<Props> = ({ metaTitle, metaDescription, metaImage }) => {
  const imageUrl = useImageUrl(metaImage);
  const title = useMemo(() => {
    return metaTitle
      ? `${metaTitle} - United Way of Greater Rochester and the Finger Lakes`
      : 'United Way of Greater Rochester and the Finger Lakes';
  }, [metaTitle]);
  return (
    <>
      {metaTitle && (
        <Helmet defaultTitle={title}>
          <title>{title}</title>
          <meta property="og:title" content={metaTitle} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaTitle} />
        </Helmet>
      )}
      {metaDescription && (
        <Helmet>
          <meta name="description" content={metaDescription} />
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:description" content={metaDescription} />
        </Helmet>
      )}
      {imageUrl && (
        <Helmet>
          <meta property="og:image:type" content="image/png" />
          <meta name="image" property="og:image" content={imageUrl}></meta>
          <meta name="twitter:image" content={imageUrl} />
        </Helmet>
      )}
    </>
  );
};
