import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  Link as MaterialLink,
  LinkProps as MaterialLinkProps,
} from '@mui/material';

export type LinkProps = MaterialLinkProps<typeof RouterLink>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <MaterialLink ref={ref} component={RouterLink} {...props} />
));

export type ButtonProps = MaterialButtonProps<typeof RouterLink>;

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonProps>((props, ref) => (
  <MaterialButton ref={ref} component={RouterLink} {...props} />
));
