/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type ApprovalQuerySortProperty =
  (typeof ApprovalQuerySortProperty)[keyof typeof ApprovalQuerySortProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalQuerySortProperty = {
  CreatedAt: 'CreatedAt',
  EventName: 'EventName',
  UserEmail: 'UserEmail',
  ApprovalStatus: 'ApprovalStatus',
} as const;
