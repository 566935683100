import React from 'react';
import { Container } from '@mui/material';
import { AppHeader } from './header';
import { Footer } from './footer/Footer';

type Props = {
  children: React.ReactNode;
};

export const Shell: React.FC<Props> = ({ children }) => {
  return (
    <>
      <AppHeader />
      <Container maxWidth="xl" sx={{ minHeight: '75vh' }}>
        {children}
      </Container>
      <Footer />
    </>
  );
};
