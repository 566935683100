import dayjs from 'dayjs';
import { ModelsFundraisingEventModel } from '../../api/agency-api';

export interface Range {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

export function getEventDayRange(event: ModelsFundraisingEventModel | null): Range | null {
  if (!event) return null;

  const eventDate = dayjs.tz(event.eventDate);
  // Allow donations to be made 15 minues before / after the event day
  return {
    start: eventDate.subtract(15, 'minutes'),
    end: eventDate.add(1, 'day').add(15, 'minutes'),
  };
}
