import { Typography, Grid } from '@mui/material';
import { FlipCardComponent } from '../../api/content';
import { FlipCard } from './FlipCard';
import { RichText } from './RichText';

export interface FlipCardSectionProps {
  title: string;
  sectionCopy: string;
  flipCards: Array<FlipCardComponent>;
}

export function FlipCardSection({ title, sectionCopy, flipCards }: FlipCardSectionProps) {
  return (
    <>
      <Typography variant="h3" component="h2" textAlign="center">
        {title}
      </Typography>
      <RichText content={sectionCopy} />
      <Grid container spacing={4} p={1}>
        {flipCards.map(flipCard => {
          return (
            <Grid item xs={12} sm={6} xl={4} key={flipCard.id}>
              <FlipCard
                frontLogo={flipCard.frontLogo.data?.attributes}
                flipImage={flipCard.flipImage.data?.attributes}
                link={flipCard.link}
                title={flipCard.title}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
