import { ApiClient, EntityItem, RelatedFile } from '../api/content';
import { createStaticQuery, unwrap } from './factory';

export interface DefaultAgencyLogo {
  logoImage: RelatedFile;
}

function getDefaultAgencyLogo() {
  return ApiClient.get<EntityItem<DefaultAgencyLogo>>('/default-agency-logo', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: DefaultAgencyLogoQuery,
  useQuery: useDefaultAgencyLogoQuery,
  prefetch: prefetchDefaultAgencyLogoQuery,
} = createStaticQuery({
  queryKey: ['default-agency-logo'],
  queryFn: unwrap(getDefaultAgencyLogo),
});
