import {
  ApiClient,
  BannerComponent,
  EntityItem,
  GrapesContent,
  PageFooterComponent,
  SEO,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface EventListPage {
  content?: GrapesContent;
  bannerComponent?: BannerComponent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

function getEventListPage() {
  return ApiClient.get<EntityItem<EventListPage>>('/event-list', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: EventListPageQuery,
  useQuery: useEventListPageQuery,
  prefetch: prefetchEventListPage,
} = createPageQuery({
  queryKey: ['event-list'],
  queryFn: unwrap(getEventListPage),
});
