import { ApiClient, BannerComponent, EntityItem, PageFooterComponent, SEO } from '../api/content';
import { createPageQuery, unwrap } from './factory';

interface NewsHomePage {
  bannerComponent?: BannerComponent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

function getNewsHomePage() {
  return ApiClient.get<EntityItem<NewsHomePage>>('/news-home', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: NewsHomePageQuery,
  useQuery: useNewsHomePageQuery,
  prefetch: prefetchNewsHomePage,
} = createPageQuery({
  queryKey: ['news-home'],
  queryFn: unwrap(getNewsHomePage),
});
