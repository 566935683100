import { useReducer } from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import { authService } from './AuthService';

export interface FlowStateStatus {
  loading: boolean;
  error?: Error;
}

export type FlowState<TStep> = {
  user?: CognitoUser | null;
  session?: CognitoUserSession;

  status: FlowStateStatus;
} & TStep;

export enum ActionType {
  Next = 'Next',
  Pending = 'Pending',
  Error = 'Error',
  Reset = 'Reset',
}

export type Action<TStep> =
  | { type: ActionType.Next; state: Partial<FlowState<TStep>> }
  | { type: ActionType.Pending }
  | { type: ActionType.Error; error: any }
  | { type: ActionType.Reset };

type Reducer<TStep> = (state: FlowState<TStep>, action: Action<TStep>) => FlowState<TStep>;

export function useAuthFlow<TStep>(initialStep: TStep) {
  return useReducer<Reducer<TStep>>(
    (current, action) => {
      switch (action.type) {
        case ActionType.Error:
          return { ...current, status: { loading: false, error: action.error } };
        case ActionType.Reset:
          return { status: { loading: false }, user: authService.getCurrentUser(), ...initialStep };
        case ActionType.Pending:
          return { ...current, status: { loading: true } };
        case ActionType.Next:
          return { ...current, ...action.state, status: { loading: false } };
      }
    },
    { status: { loading: false }, user: authService.getCurrentUser(), ...initialStep }
  );
}
