import { Container, Stack } from '@mui/material';
import { Configure } from 'react-instantsearch';

import { GlobalSearchProvider } from '../providers';
import { Banner, DynamicZone, Meta } from '../shared/components';
import { EmptySearchBoundry, GlobalSearch, GlobalSearchGrid } from './components';
import { useGlobalSearchPageQuery } from '../queries';

export default function SearchPage() {
  const { data } = useGlobalSearchPageQuery();

  return (
    <GlobalSearchProvider>
      <Configure hitsPerPage={20} />
      {data.attributes.SEO && (
        <Meta {...data.attributes.SEO} metaImage={data.attributes.SEO.metaImage.data.attributes} />
      )}
      {data.attributes.bannerComponent && (
        <Banner bannerComponent={data.attributes.bannerComponent} />
      )}
      <Container maxWidth="md">
        <Stack p={0}>
          <GlobalSearch />
          <EmptySearchBoundry>
            <GlobalSearchGrid />
          </EmptySearchBoundry>
        </Stack>
      </Container>
      {data.attributes.contentComponents && (
        <DynamicZone components={data.attributes.contentComponents} />
      )}
    </GlobalSearchProvider>
  );
}
