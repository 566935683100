import dayjs from 'dayjs';

const eastern_tz = 'America/New_York';
export function formatTimestamp(
  timestamp: string | Date | number | undefined,
  format: string = 'M/D/YYYY h:mm a',
  inputFormat?: string
) {
  return dayjs(timestamp, inputFormat).tz(eastern_tz).format(format);
}
