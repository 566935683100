import { useMemo } from 'react';
import { ApiClient, EntityCollection } from '../api/content';
import { createPageQuery, unwrap } from './factory';
import { LeadershipGivingNetwork } from './useLeadershipGivingPageQuery';

function getLeadershipGivingNetworksDetails(slug: string) {
  return ApiClient.get<EntityCollection<LeadershipGivingNetwork>>(`/leadership-giving-networks`, {
    params: {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      populate: 'deep',
    },
  });
}

export function getLeadershipGivingNetworksDetailsQuery(slug: string) {
  return createPageQuery({
    queryKey: ['network', slug],
    queryFn: unwrap(() => getLeadershipGivingNetworksDetails(slug)),
  });
}

export function useLeadershipGivingNetworksDetailsQuery(slug: string) {
  const { useQuery } = useMemo(() => getLeadershipGivingNetworksDetailsQuery(slug), [slug]);

  return useQuery();
}
