import { ApiClient, BannerComponent, DynamicComponentTypes, EntityItem } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface HomePage {
  bannerComponent: BannerComponent;
  contentComponents: Array<DynamicComponentTypes>;
}
function getHomePage() {
  return ApiClient.get<EntityItem<HomePage>>('/home-page', {
    params: {
      populate: 'deep',
    },
  });
}

export const { query: HomePageQuery, useQuery: useHomePageQuery } = createPageQuery({
  queryKey: ['home'],
  queryFn: unwrap(getHomePage),
});
