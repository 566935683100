import { createTheme } from '@mui/material';
import { common, grey } from '@mui/material/colors';

const palette = {
  primary: {
    light: '#7ea0c3',
    main: '#00518a',
    dark: '#002844',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#069fc8',
    main: '#0e3d2a',
    dark: '#074a74',
    contrastText: '#ffffff',
  },
  transparent: {
    white: 'rgb(255 255 255 / 50%)',
    black: 'rgb(0 0 0 / 80%)',
    blue: 'rgb(2 83 139 / 50%)',
    total: 'rgb(0, 0, 0, 0)',
    contrastText: '#fff',
  },
  grey: {
    400: '#f1f1f1',
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 767,
    md: 1024,
    lg: 1366,
    xl: 1700,
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    transparent: {
      white: string;
      black: string;
      blue: string;
      total: string;
      contrastText: string;
    };
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    linkBoxCTA: true;
    greyPaper: true;
    blueBars: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    greyButton: true;
  }
}

const defaults = createTheme({ palette, breakpoints });
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1024,
      lg: 1366,
      xl: 1700,
    },
  },

  palette,
  typography: {
    fontFamily: 'Roboto',
    htmlFontSize: 18,
    h1: {
      fontFamily: 'Roboto',
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      marginBottom: '1rem',
      marginTop: '1rem',
      lineHeight: '.85',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '2.5rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      [defaults.breakpoints.up('xl')]: {
        fontSize: '5rem',
      },
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: '900',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      marginBottom: '1rem',
      marginTop: '1rem',
      textAlign: 'left',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '1.5rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
      [defaults.breakpoints.up('lg')]: {
        fontSize: '4.4rem',
      },
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: '900',
      letterSpacing: '1.17px',
      marginBottom: '1rem',
      marginTop: '1rem',
      color: palette.primary.main,
      [defaults.breakpoints.up('xs')]: {
        fontSize: '1.5rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
      [defaults.breakpoints.up('lg')]: {
        fontSize: '3.3rem',
      },
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      textTransform: 'uppercase',
      letterSpacing: 'normal',
      marginBottom: '1rem',
      marginTop: '1rem',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '1.5rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
      [defaults.breakpoints.up('lg')]: {
        fontSize: '2.5rem',
      },
    },
    h5: {
      fontFamily: 'Roboto',
      fontSize: '1.3rem',
      fontWeight: '700',
      textTransform: 'uppercase',
      letterSpacing: '0.28px',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    h6: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      letterSpacing: '0.28px',
      marginBottom: '1rem',
      marginTop: '0',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '.75rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
      [defaults.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
      [defaults.breakpoints.up('xl')]: {
        fontSize: '1.34rem',
      },
    },
    subtitle1: {
      fontSize: '.75rem',
      textTransform: 'uppercase',
      letterSpacing: '0.28px',
    },
    subtitle2: {
      fontSize: '.75rem',
      fontWeight: '700',
      letterSpacing: '0.28px',
    },
    body1: {
      letterSpacing: '0.28px',
      fontFamily: 'Roboto',
      marginBottom: '1rem',
      fontWeight: 400,
      textAlign: 'left',
      [defaults.breakpoints.up('xs')]: {
        fontSize: '.75rem',
      },
      [defaults.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
      [defaults.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
      [defaults.breakpoints.up('xl')]: {
        fontSize: '1.34rem',
      },
    },
    body2: {
      fontSize: '.75rem',
      letterSpacing: '0.28px',
    },
  },
  components: {
    /*Link*/
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontWeight: '900',
          textDecoration: 'none',
          color: palette.primary.main,
        },
      },
    },

    /*Drawer*/
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1000',
          '& .MuiPaper-root': {
            backgroundColor: common.white,

            width: '100%',
            padding: '2rem 0',
            [defaults.breakpoints.up('xs')]: {
              marginTop: '80px',
              height: 'calc(100vh - 80px)',
            },
            [defaults.breakpoints.up('md')]: {
              marginTop: '6rem',
              height: 'calc(100vh - 6rem)',
            },
          },
          '& .MuiDrawer-paper': {
            zIndex: '10',
          },
        },
      },
    },

    /*Container*/
    MuiContainer: {
      styleOverrides: {
        root: { padding: '2rem 0', maxWidth: '1700px', boxSizing: 'border-box' },
        maxWidthXl: {
          [defaults.breakpoints.up('xs')]: {
            maxWidth: '1700px',
            padding: '0',
          },
        },
        maxWidthLg: {
          [defaults.breakpoints.up('xs')]: {
            maxWidth: '1366px',
            padding: '0',
          },
          [defaults.breakpoints.up('sm')]: {
            maxWidth: '1366px',
            padding: '0',
          },
        },
      },
    },

    /* Card*/
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '.5rem',
        },
      },
    },

    /*Buttons*/
    MuiButton: {
      variants: [
        {
          props: { variant: 'greyButton' },
          style: {
            backgroundColor: grey[300],
            fontFamily: 'Roboto',
            borderRadius: '0',
            lineHeight: 'normal',
            padding: '.5rem',
            marginBottom: '1rem',
            fontSize: '1.8rem',
            width: 'fit-content',
            transition: 'all .25s ease !important',
            boxShadow: '0px 0px 0px rgb(0 0 0 / 0%)',
            color: palette.primary.main,
            '&:hover': {
              boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
              backgroundColor: palette.primary.main,
              color: common.white,
            },
          },
        },
      ],
      styleOverrides: {
        contained: {
          backgroundColor: palette.primary.light,
          fontFamily: 'Roboto',
          borderRadius: '0',
          lineHeight: 'normal',
          padding: '.5rem',
          transition: 'all .25s ease !important',
          '& p': { margin: '0 .5rem', color: common.white },
          '& hover': {
            marginTop: '-0.25rem',
            marginBottom: '0.25rem',
            boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            backgroundColor: palette.primary.main,
          },
        },
        containedPrimary: {
          backgroundColor: palette.primary.light,
          fontFamily: 'Roboto',
          borderRadius: '0',
          lineHeight: 'normal',
          padding: '.5rem',
          fontSize: '1rem',
          width: 'fit-content',
          transition: 'all .25s ease !important',
          boxShadow: '0px 0px 0px rgb(0 0 0 / 0%)',
          '& p': { margin: '0', color: common.white },
          '& hover': {
            marginTop: '-0.25rem',
            marginBottom: '0.25rem',
            boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            backgroundColor: palette.primary.main,
          },
        },
        outlinedPrimary: {
          backgroundColor: palette.primary.light,
          borderColor: common.white,
          color: common.white,
          fontFamily: 'Roboto',
          borderRadius: '0',
          lineHeight: 'normal',
          padding: '.5rem',
          fontSize: '1rem',
          transition: 'all .25s ease !important',
          boxShadow: '0px 0px 0px rgb(0 0 0 / 0%)',
          '& p': { margin: '0', color: common.white },
          '&:hover': {
            backgroundColor: palette.primary.main,
            borderColor: common.white,
          },
        },
        containedSecondary: {
          backgroundColor: grey[500],
          borderColor: grey[200],
          color: common.white,
          fontFamily: 'Roboto',
          borderRadius: '0',
          lineHeight: 'normal',
          padding: '.5rem',
          fontSize: '.75rem',
          transition: 'all .25s ease !important',
          boxShadow: '0px 0px 0px rgb(0 0 0 / 0%)',
          width: '100%',
          '& p': { margin: '0', color: common.white },
          '& hover': {
            marginTop: '-0.25rem',
            marginBottom: '0.25rem',
            boxShadow: '0px 16px 16px rgb(0 0 0 / 50%)',
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    /*! Tabs */

    MuiTabs: {
      styleOverrides: {
        root: { width: '100%', backgroundColor: palette.grey[400], minWidth: '15%' },
        indicator: { backgroundColor: palette.primary.main },
        scrollableY: {},
        scrollableX: { width: '100%' },
        vertical: { width: '20%', backgroundColor: common.white },
      },
    },

    /*! inputs */
    MuiFilledInput: {
      styleOverrides: {
        root: {
          margin: '0rem',
          borderColor: common.white,
          backgroundColor: palette.primary.light,
          border: 'solid thin',
          borderRadius: '0px',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        shrink: {
          padding: '1rem 0rem',
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            fontSize: '1rem',
          },
          '& .MuiButtonBase-root.MuiMenuItem-root': { fontSize: '1rem' },
          padding: '0',
          fontSize: '1rem',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontSize: '.75rem',
        },
        input: { marginBottom: '0px' },
        menuItem: { fontSize: '.75rem!important', marginBottom: '.25rem' },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& p': { margin: 0, color: grey[400] },
          '&+.MuiOutlinedInput-input': { paddingLeft: '.5rem' },
        },
      },
    },

    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '1rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          marginBottom: '.5rem',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: { marginLeft: '0px', marginRight: '0px', '&.Mui-error': { fontWeight: '700' } },
      },
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '1rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
            padding: '1rem 0rem',
          },
          '& .MuiSelect-select': { padding: '.75rem 1rem' },
          padding: '0',
          backgroundColor: grey[200],
          fontSize: '1rem',
          '& label': {
            padding: '.25rem 1rem',
            color: palette.primary.main,
          },
          '& .MuiInput-underline': {
            margin: '1rem 0rem',
            '&::after': { display: 'none' },
            '&::before': { display: 'none' },
          },
          '& input': {
            padding: '.75rem 1rem',
            color: palette.primary.main,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: common.white,
            fontSize: '1rem',
            '& label': {
              color: grey[600],
              padding: '0',
              fontSize: '1rem',
            },

            '& input': {
              color: common.black,
            },
            '& fieldset': {
              borderRadius: 0,
              fontSize: '1rem',
            },
            '& .MuiInputBase-root': {
              marginBottom: '0px',
            },
            '& .MuiInputLabel-root.MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
              padding: '0px',
            },
          },
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: {
            marginBottom: '1rem',
            '& input': {
              fontSize: '1rem',
            },
          },
        },
      ],
    },

    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: '1rem',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '1rem',
            padding: '.75rem 1rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          marginBottom: '0',
          backgroundColor: common.white,
          borderRadius: '0',
          '& .MuiSelect-select': {
            padding: '.75rem 1rem',
            fontSize: '1rem',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& legend': { fontSize: '1rem' },
            '& .MuiInputLabel-root.MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },

            backgroundColor: common.white,
            marginBottom: '0px',
            '&::before': {
              borderBottom: '0px',
            },
            '& .MuiFormLabel-root': {
              fontSize: '.5rem',
            },
            '& .MuiSelect-select': {
              padding: '.75rem 1rem',
              fontSize: '1rem',
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputBase-root, & .MuiInputLabel-root': {
              fontSize: '1rem',
            },
            '& .MuiInputLabel-root.MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            backgroundColor: common.white,
            marginBottom: '0px',
            '&::before': {
              borderBottom: '0px',
            },
            '& .MuiFormLabel-root': {
              fontSize: '.5rem',
            },
            '& .MuiSelect-select': {
              padding: '.5rem',
              fontSize: '1rem',
            },
          },
        },
      ],
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '1rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          padding: '.175rem',
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-root': { alignItems: 'center' },
          '& .MuiInputBase-root, & .MuiInputLabel-root': {
            fontSize: '1rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
          padding: '.175rem',
          borderRadius: '0',
          fontSize: '1rem',
          alignItems: 'center',
          color: grey[400],
          '&.Mui-checked': {
            color: palette.primary.main,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingLeft: '.5rem',
          alignItems: 'center',
          '& .MuiTypography-body1': {
            paddingLeft: '.5rem',
            paddingTop: '.125rem',
            marginBottom: '0',
            color: palette.primary.main,
          },
        },
      },
    },

    /* List */

    MuiList: {
      styleOverrides: {
        root: {
          listStyleType: 'disc',
          paddingLeft: '8px',
          paddingRight: '8px',
          '& .MuiListItem-root': {
            display: 'list-item',
            [defaults.breakpoints.up('xs')]: {
              fontSize: '.75rem',
            },
            [defaults.breakpoints.up('md')]: {
              fontSize: '1rem',
            },
            [defaults.breakpoints.up('lg')]: {
              fontSize: '1.25rem',
            },
            [defaults.breakpoints.up('xl')]: {
              fontSize: '1.34rem',
            },
          },
          '&.MuiMenu-list': {
            paddingLeft: '0',
            paddingRight: '0',
          },
          '& .MuiButtonBase-root.MuiMenuItem-root': {
            display: 'list-item',
            fontSize: '1rem',
            marginBottom: '0',
            marginTop: '0',
            padding: '.5rem .75rem',
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    /* Accordion */
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            margin: '.5rem 0',
            '&.Mui-expanded': {
              margin: '.5rem 0',
            },
          },
        },
      },
    },

    /* Paper*/
    MuiPaper: {
      variants: [
        {
          props: { variant: 'linkBoxCTA' },
          style: {
            backgroundColor: grey[200],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            [defaults.breakpoints.up('xs')]: {
              width: '100%',
              height: 'auto',
              '& a': {
                fontSize: '1.5rem',
              },
            },
            [defaults.breakpoints.up('lg')]: {
              width: '33%',
              height: '300px',
              '& a': {
                fontSize: '2.5rem',
              },
            },
          },
        },
        {
          props: { variant: 'greyPaper' },
          style: {
            backgroundColor: grey[200],
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            padding: '1rem',
          },
        },
        {
          props: { variant: 'blueBars' },
          style: {
            backgroundColor: palette.primary.main,
            borderRadius: '0',
            [defaults.breakpoints.up('xs')]: {
              width: '0px',
              padding: '0',
            },
            [defaults.breakpoints.up('md')]: {
              width: '35%',
              padding: '1rem',
            },
          },
        },
      ],
    },
  },
});
