import { RichTextContent } from '../../api/content';
import ReactMarkdown from 'react-markdown';

export interface RichTextProps {
  className?: string;
  content: RichTextContent;
}

export function RichText({ className, content }: RichTextProps) {
  return <ReactMarkdown className={className}>{content}</ReactMarkdown>;
}
