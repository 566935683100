import {
  AlumniComponent,
  ApiClient,
  EntityCollection,
  EntityItem,
  GrapesContent,
  LeadershipComponent,
  LearnMoreInfoComponent,
  ReviewQuoteComponent,
  ScholarshipComponent,
  SponsorsComponent,
  File,
  SEO,
  BannerComponent,
  PageFooterComponent,
  DynamicComponentTypes,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface LeadershipDevelopmentProgram {
  slug: string;
  title: string;
  content?: GrapesContent;
  reviewQuote?: ReviewQuoteComponent;
  content2?: GrapesContent;
  learnMoreInfo?: Array<LearnMoreInfoComponent>;
  alumni?: Array<AlumniComponent>;
  sponsors?: Array<SponsorsComponent>;
  scholarship?: ScholarshipComponent;
  leadership?: Array<LeadershipComponent>;
  flipImage: EntityItem<File>;
  bannerComponent?: BannerComponent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

interface LeadershipDevelopmentCategory {
  title: string;
  programs: EntityCollection<LeadershipDevelopmentProgram>;
}

interface LeadershipDevelopmentHomePage {
  categories: EntityCollection<LeadershipDevelopmentCategory>;
  contentComponents?: Array<DynamicComponentTypes>;
  bannerComponent?: BannerComponent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

function getLeadershipDevelopmentHomePage() {
  return ApiClient.get<EntityItem<LeadershipDevelopmentHomePage>>('/leadership-development-home', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: LeadershipDevelopmentHomePageQuery,
  useQuery: useLeadershipDevelopmentHomePageQuery,
  prefetch: prefetchLeadershipDevelopmentHomePage,
} = createPageQuery({
  queryKey: ['leadership-development-home'],
  queryFn: unwrap(getLeadershipDevelopmentHomePage),
});
