import { Button, SxProps } from '@mui/material';
import { getGetAgencyByIdQueryKey, useUpdateActiveStatus } from '../../api/agency-api';
import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { formatError } from '../../shared/utils/formatError';
import { useQueryClient } from '@tanstack/react-query';

interface ApprovalButtonsProps {
  agencyId: string;
  isActive: boolean;
  sx?: SxProps;
}

export default function AgencyActivateButton({ agencyId, isActive, sx }: ApprovalButtonsProps) {
  const queryClient = useQueryClient();
  const { mutate: updateActiveStatus } = useUpdateActiveStatus({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Update successful', { variant: 'success' });
        queryClient.invalidateQueries({ queryKey: getGetAgencyByIdQueryKey(agencyId) });
      },
      onError: (error: Error) => {
        const { message } = formatError(error);
        enqueueSnackbar(message, { variant: 'error' });
      },
    },
  });

  const onActiveUpdate = useCallback(() => {
    updateActiveStatus({
      data: {
        agencyId: agencyId,
        setActive: !isActive,
      },
    });
  }, [agencyId, isActive, updateActiveStatus]);
  return (
    <Button variant="outlined" sx={sx} onClick={onActiveUpdate}>
      {isActive ? 'Deactivate' : 'Activate'}
    </Button>
  );
}
