import { Alert, Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

const previewCookieName = 'content_preview';

export const ContentPreviewAlert: React.FC = () => {
  const [cookie, , removeCookie] = useCookies([previewCookieName]);

  const isPreviewMode = useMemo(() => !!cookie.content_preview, [cookie]);

  if (!isPreviewMode) return null;

  return (
    <Alert
      severity="success"
      sx={{ alignItems: 'center', display: 'flex', pt: 0, pb: 0, borderRadius: '0' }}
      icon={false}
      action={
        <Button
          color="inherit"
          size="medium"
          sx={{ verticalAlign: 'center' }}
          onClick={() => removeCookie(previewCookieName)}
        >
          Exit Preview
        </Button>
      }
    >
      <Typography variant="h5" marginTop=".2rem" marginBottom=".2rem">
        Preview Mode
      </Typography>
    </Alert>
  );
};
