import React, { PropsWithChildren } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { APP_CONFIG } from '../shared/config';
import { useCart } from '../cart';

const stripe = loadStripe(APP_CONFIG.Donately.StripeKey);

export const StripeElementsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const cart = useCart();

  if (cart.total <= 0) return null;

  return (
    <Elements stripe={stripe} options={{ mode: 'payment', currency: 'usd', amount: cart.total }}>
      {children}
    </Elements>
  );
};
