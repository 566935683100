import React from 'react';
import { Box } from '@mui/material';

type Props = {
  children?: React.ReactNode;
  bannerImage?: React.ReactNode;
  tabletBannerImage?: React.ReactNode;
  mobileBannerImage?: React.ReactNode;
  height?: string;
  tabletHeight?: string;
  mobileHeight?: string;
  align?: string;
  justify?: string;
  padding?: number;
  bannerBGColor?: string;
};

export const ContentSection: React.FC<Props> = ({
  children,
  bannerImage,
  tabletBannerImage,
  mobileBannerImage,
  height,
  tabletHeight,
  mobileHeight,
  align,
  justify,
  padding,
  bannerBGColor,
}) => {
  const mobileBG = mobileBannerImage ?? bannerImage;
  const tabletBG = tabletBannerImage ?? bannerImage;
  return (
    <Box
      display="flex"
      alignItems={align}
      justifyContent={justify}
      p={padding}
      sx={{
        backgroundImage: {
          xs: 'URL(' + mobileBG + ')',
          sm: 'URL(' + tabletBG + ')',
          md: 'URL(' + bannerImage + ')',
        },
        backgroundColor: bannerBGColor,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: { xs: mobileHeight, sm: tabletHeight, lg: height },
        width: '100%',
        flexDirection: 'column',
      }}
    >
      {children}
    </Box>
  );
};
