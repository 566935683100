import {
  ApiClient,
  EntityCollection,
  EntityItem,
  GrapesContent,
  ReviewQuoteComponent,
  File,
  SEO,
  PageFooterComponent,
  BannerComponent,
} from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface LeadershipGivingNetwork {
  slug: string;
  title: string;
  bannerComponent?: BannerComponent;
  content?: GrapesContent;
  content2?: GrapesContent;
  reviewQuote?: ReviewQuoteComponent;
  flipImage: EntityItem<File>;
  frontLogo: EntityItem<File>;
  description: string;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

interface LeadershipGivingPage {
  bannerComponent?: BannerComponent;
  content?: GrapesContent;
  networks: EntityCollection<LeadershipGivingNetwork>;
  content2?: GrapesContent;
  pageFooter?: PageFooterComponent;
  SEO?: SEO;
}

function getLeadershipGivingPage() {
  return ApiClient.get<EntityItem<LeadershipGivingPage>>('/leadership-giving', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: LeadershipGivingPageQuery,
  useQuery: useLeadershipGivingPageQuery,
  prefetch: prefetchLeadershipGivingPage,
} = createPageQuery({
  queryKey: ['leadership-giving'],
  queryFn: unwrap(getLeadershipGivingPage),
});
