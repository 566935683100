import Axios, { AxiosRequestConfig } from 'axios';
import { APP_CONFIG } from '../../shared/config';

const { Api } = APP_CONFIG;

type TokenRef = () => Promise<string | undefined>;
const unauthenticated: TokenRef = () => Promise.resolve(undefined);

let getToken: TokenRef = unauthenticated;

export function setJwtToken(tokenRef: TokenRef) {
  getToken = tokenRef;
}

export function clearJwtToken() {
  getToken = unauthenticated;
}

export const AXIOS_INSTANCE = Axios.create({
  baseURL: Api.BaseUrl,
});

AXIOS_INSTANCE.interceptors.request.use(async config => {
  const token = await getToken();
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const apiRequest = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...(options ?? {}),
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-expect-error
  promise.cancel = source.cancel;

  return promise;
};
