import { ApiClient } from '../api/content';
import { createStaticQuery } from './factory';

const KEY = 'redirects';

export enum RedirectType {
  Permanent = 'moved_permanently_301',
  Found = 'found_302',
  Temporary = 'temporary_redirect_307',
  Gone = 'gone_410',
  Unavailable = 'unavailable_for_legal_reasons_451',
}

export interface Redirect {
  id: number;
  to: string;
  from: string;
  type: RedirectType;
}

interface Response {
  redirects: Array<Redirect>;
  total: number;
}

function fetchRedirects() {
  return ApiClient.get<Response>(`/redirects`);
}

export const {
  query: RedirectsQuery,
  useQuery: useRedirectsQuery,
  prefetch: prefetchRedirects,
} = createStaticQuery({
  queryKey: [KEY],
  queryFn: async () => {
    const { data } = await fetchRedirects();
    return data.redirects;
  },
});
