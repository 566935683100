import React, { useLayoutEffect, useMemo, useRef } from 'react';

import { GrapesContent } from '../../api/content';
import { styled } from '@mui/material';

interface Props {
  data?: GrapesContent;
}

const EMPTY = { css: '', html: '' };

/**
 * Renders visual content created with grapesjs.
 */
export const VisualContent: React.FC<Props> = ({ data }) => {
  const { css, html } = data ?? EMPTY;

  // create a ref to the content node
  const contentNode = useRef<HTMLDivElement>(null);

  // create a styled component with the css
  const Root = useMemo(() => styled('div')(css), [css]);

  const content = useRef(EMPTY.html);

  // apply the html markup to the content node
  useLayoutEffect(() => {
    if (!content.current) {
      setHtml(contentNode, html);
      content.current = html;
    } else if (content.current !== html) {
      console.warn('VisualContent: html was updated after initial render. This is not supported.');
    }
  }, [html]);

  return <Root className="vc-root" ref={contentNode} />;
};

function setHtml(ref: React.RefObject<HTMLDivElement>, html: string) {
  if (!ref.current) return;

  // use a document fragment so that we can append script tags as well
  const range = document.createRange();
  range.selectNode(ref.current);
  const documentFragment = range.createContextualFragment(html);

  ref.current.innerHTML = '';
  ref.current.append(documentFragment);
}
