import { ApiClient, EntityItem, GrapesContent, RelatedFile } from '../api/content';
import { createStaticQuery, unwrap } from './factory';

export interface DonateModal {
  bannerImage: RelatedFile;
  leftContent: GrapesContent;
  rightContent: GrapesContent;
  enabled: boolean;
}

function getDonateModal() {
  return ApiClient.get<EntityItem<DonateModal>>('/donate', {
    params: {
      populate: {
        bannerImage: '*',
        leftContent: '*',
        rightContent: '*',
      },
    },
  });
}

export const {
  query: DonateModalQuery,
  useQuery: useDonateModalQuery,
  prefetch: prefetchDonateModal,
} = createStaticQuery({
  queryKey: ['donate'],
  queryFn: unwrap(getDonateModal),
});
