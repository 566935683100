import defaultsDeep from 'lodash.defaultsdeep';
import { CartState, DEFAULT_CART } from './CartContext';

const CART_STORAGE_KEY = 'uw_dontation_cart';

export interface ICartStorageProvider {
  write(cart: CartState): void;
  read(): CartState;
}

class CartStorageProvider implements ICartStorageProvider {
  write(cart: CartState): void {
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(cart));
  }

  read(): CartState {
    try {
      const cartJson = localStorage.getItem(CART_STORAGE_KEY) ?? '{}';
      const cart = JSON.parse(cartJson);

      return defaultsDeep(cart, DEFAULT_CART);
    } catch {
      return { ...DEFAULT_CART };
    }
  }
}

export const cartStorage: ICartStorageProvider = new CartStorageProvider();
