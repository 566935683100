import { Grid, InputAdornment, Typography, styled } from '@mui/material';
import { TextInput } from '../../shared/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared/icons';
import GuideStar from '../../assets/logos/guidstarGrey.png';
import CharityNavigator from '../../assets/logos/charityNavGrey.png';
import { Image } from '../../shared/components';

const SocialAdornment = styled(InputAdornment)(({ theme }) => ({
  width: '1rem',
}));

export default function AgencySocialMedia() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" component="h2" color="primary.main">
          Social Media
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.twitter"
          variant="outlined"
          label="X Username (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start">
                <FontAwesomeIcon icon={icons.twitterx} /> &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.facebook"
          variant="outlined"
          label="Facebook Profile (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start">
                <FontAwesomeIcon icon={icons.facebook} />
                &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.tikTok"
          variant="outlined"
          label="TikTok Profile"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={icons.tikTok} />
                &nbsp;:
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.instagram"
          variant="outlined"
          label="Instagram Profile"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={icons.instagram} />
                &nbsp;:
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.youTube"
          variant="outlined"
          label="YouTube Link (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start">
                <FontAwesomeIcon icon={icons.youtube} />
                &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.linkedIn"
          variant="outlined"
          label="LinkedIn Profile Page (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start" className="socialIcon">
                <FontAwesomeIcon icon={icons.linkedin} />
                &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.charityNavigator"
          variant="outlined"
          label="Charity Navigator (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start">
                <Image src={CharityNavigator} sx={{ width: '100%' }} />
                &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="social.guideStar"
          variant="outlined"
          label="Guidestar (Optional)"
          InputProps={{
            startAdornment: (
              <SocialAdornment position="start">
                <Image src={GuideStar} sx={{ width: '100%' }} />
                &nbsp;:
              </SocialAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
