import React, { PropsWithChildren } from 'react';
import { useInstantSearch } from 'react-instantsearch';

interface Props extends PropsWithChildren {
  fallback?: React.ReactNode;
}

export const EmptySearchBoundry: React.FC<Props> = ({ fallback, children }) => {
  const { indexUiState } = useInstantSearch();

  return (
    <>
      {!indexUiState.query && fallback}
      <div hidden={!indexUiState.query}>{children}</div>
    </>
  );
};
