import { UseQueryOptions, UseSuspenseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from '../providers';
import { AxiosResponse } from 'axios';
import { Entity, EntityCollection, EntityItem } from '../api/content';

const defaultQueryOptions: Record<string, Pick<UseQueryOptions, 'staleTime' | 'retry'>> = {
  static: {
    staleTime: Infinity,
    retry: 3,
  },
  page: {
    staleTime: 5 * 60 * 1000, // 5 minutes
    retry: 3,
  },
};

function createQuery<T>(
  options: UseSuspenseQueryOptions<T>,
  defaults?: keyof typeof defaultQueryOptions
) {
  const query = {
    // merge default options with provided options
    ...(defaults ? defaultQueryOptions[defaults] : {}),
    ...options,
  };

  // suspense query hook
  const useQuery = () => useSuspenseQuery(query);

  // prefetch handler
  const prefetch = () => queryClient.prefetchQuery(query);

  return { query, useQuery, prefetch };
}

/**
 * Unwraps the Strapi EntityItem response accessed through an Axios client.
 */
export function unwrap<T>(
  queryFn: () => Promise<AxiosResponse<EntityItem<T>>>
): () => Promise<Entity<T>>;
export function unwrap<T>(
  queryFn: () => Promise<AxiosResponse<EntityCollection<T>>>
): () => Promise<Array<Entity<T>>>;
export function unwrap<T>(
  queryFn: () => Promise<AxiosResponse<EntityItem<T> | EntityCollection<T>>>
): () => Promise<Entity<T> | Array<Entity<T>>> {
  return async () => {
    const { data } = await queryFn();
    return data.data;
  };
}

/**
 * Constructs query options for a static query which is expected to be cached indefinitely.
 */
export function createStaticQuery<T>(options: UseSuspenseQueryOptions<T>) {
  return createQuery(options, 'static');
}

/**
 * Constructs query options for a page query which is expected to be cached for some time.
 */
export function createPageQuery<T>(options: UseSuspenseQueryOptions<T>) {
  return createQuery(options, 'page');
}
