import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { newPasswordSchema } from '../../auth';
import { ModelsUserModel as User, useSetTemporaryPassword } from '../../api/agency-api';
import { TextInput } from '../../shared/forms';

interface TempPasswordFields {
  password: string;
  confirmPassword: string;
}

const defaultValues = { password: '', confirmPassword: '' };

interface Props {
  user: User;
}

export const SetTemporaryPasswordForm: React.FC<Props> = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<TempPasswordFields>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { mutate } = useSetTemporaryPassword({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Temporary password set successfully', { variant: 'success' });
        form.reset(defaultValues);
      },
    },
  });

  return (
    <FormProvider {...form}>
      <Typography variant="h5" component="h2" color="primary.main">
        Set Temporary Password
      </Typography>
      <Stack spacing={2}>
        <TextInput
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          required
          autoComplete="off"
        />
        <TextInput
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          variant="outlined"
          fullWidth
          required
          autoComplete="off"
        />
        <Button
          type="button"
          variant="contained"
          onClick={form.handleSubmit(params => mutate({ userId: user.id, params }))}
        >
          Submit
        </Button>
      </Stack>
    </FormProvider>
  );
};

const schema = yup.object({
  password: newPasswordSchema.label('Password'),
  confirmPassword: yup
    .string()
    .label('Confirm Password')
    .required()
    .test(
      'matches-password',
      'Passwords do not match.',
      (value, context) => value === context.parent.password
    ),
});
