import { Controller, FieldValues, Path, useFormState } from 'react-hook-form';
import { Autocomplete, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';

interface Props<
  TFieldValues extends FieldValues = FieldValues & {
    options: DropDownItem[];
    label?: string;
    getOptionKey(option: DropDownItem): string | number;
    getOptionLabel(option: DropDownItem): string;
  }
> extends Omit<TextFieldProps, 'name'> {
  name: Path<TFieldValues>;
}
export interface DropDownItem {
  id: number | string;
  item: string;
}
export function DropDownSelect<TFieldValues extends FieldValues = FieldValues>({
  options,
  getOptionKey,
  getOptionLabel,
  ...props
}: Props<TFieldValues> & {
  options: DropDownItem[];
  getOptionKey(option: DropDownItem): string | number;
  getOptionLabel(option: DropDownItem): string;
}) {
  const { errors } = useFormState();

  return (
    <Controller
      name={props.name}
      render={({ field: { ref, onChange, value: keyValue, ...fieldProps } }) => {
        const currentOption: DropDownItem | null =
          options.find(opt => getOptionKey(opt) === keyValue) ?? null;
        return (
          <>
            <Autocomplete
              freeSolo={false}
              multiple={false}
              value={currentOption}
              options={options}
              onChange={(_, newLabelValue) =>
                onChange(newLabelValue ? getOptionKey(newLabelValue) : null)
              }
              getOptionLabel={option => getOptionLabel(option)}
              renderInput={({ InputProps, ...params }: any) => (
                <TextField
                  {...fieldProps}
                  {...props}
                  {...params}
                  inputRef={ref}
                  InputProps={{ ...InputProps }}
                />
              )}
            ></Autocomplete>
            <ErrorMessage
              errors={errors}
              name={props.name}
              render={data => <FormHelperText error>{data.message}</FormHelperText>}
            />
          </>
        );
      }}
    />
  );
}
