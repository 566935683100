import React, { useMemo } from 'react';
import { styled, SxProps } from '@mui/system';

import { File } from '../../api/content';
import { formatMediaUrl, ImageSize, isFile } from '../utils';

interface Props {
  src: File | string;
  size?: ImageSize;
  alt?: string;
  sx?: SxProps;
  className?: string;
}

const ImageStyled = styled('img')();

export const Image: React.FC<Props> = ({ src, size, alt, className, sx = {} }) => {
  const url = useMemo(() => (isFile(src) ? formatMediaUrl(src, size) : src), [src, size]);
  const altText = useMemo(() => (isFile(src) ? alt ?? src.alternativeText : alt), [src, alt]);

  return <ImageStyled src={url} alt={altText} className={className} sx={sx} />;
};
