import * as yup from 'yup';

const contactSchema = yup.object({
  title: yup
    .string()
    .nullable()
    .transform(value => value?.trim()),
  name: yup.object({
    first: yup
      .string()
      .label('First Name')
      .required()
      .transform(value => value?.trim()),
    last: yup
      .string()
      .label('Last Name')
      .required()
      .transform(value => value?.trim()),
  }),
  emailAddress: yup
    .string()
    .label('Email')
    .email()
    .required()
    .transform(value => value?.trim()),
  phoneNumber: yup.string().label('Phone').required(),
});

const contactSchema2 = yup.object({
  title: yup.string().nullable(),
  name: yup.lazy(() =>
    yup.object().when(['emailAddress', 'phoneNumber'], {
      is: (emailAddress?: string, phoneNumber?: string) => !!emailAddress || !!phoneNumber,
      then: schema =>
        schema.shape({
          first: yup
            .string()
            .label('First Name')
            .required()
            .transform(value => value?.trim()),
          last: yup
            .string()
            .label('Last Name')
            .required()
            .transform(value => value?.trim()),
        }),
      otherwise: schema =>
        schema.shape({
          first: yup
            .string()
            .label('First Name')
            .nullable()
            .transform(value => value?.trim()),
          last: yup
            .string()
            .label('Last Name')
            .nullable()
            .transform(value => value?.trim()),
        }),
    })
  ),
  emailAddress: yup.lazy(() =>
    yup
      .string()
      .label('Email')
      .email()
      .when(['name', 'phoneNumber'], {
        is: (name?: any, phoneNumber?: string) => !!name?.first || !!name?.last || !!phoneNumber,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable(),
      })
      .transform(value => value?.trim())
  ),
  phoneNumber: yup.lazy(() =>
    yup
      .string()
      .label('Phone')
      .when(['name', 'emailAddress'], {
        is: (name?: any, emailAddress?: string) => !!name?.first || !!name?.last || !!emailAddress,
        then: schema => schema.required(),
        otherwise: schema => schema.nullable(),
      })
  ),
});

const grantSchema = yup.object({
  enabled: yup.bool().nullable(),
  description: yup.string().nullable(),
  amount: yup
    .string()
    .nullable()
    .transform(value => value?.trim()),
  title: yup
    .string()
    .nullable()
    .transform(value => value?.trim()),
});

const socialSchema = yup.object({
  charityNavigator: yup.string().nullable(),
  facebook: yup.string().nullable(),
  guideStar: yup.string().nullable(),
  instagram: yup.string().nullable(),
  linkedIn: yup.string().nullable(),
  tikTok: yup.string().nullable(),
  twitter: yup.string().nullable(),
  youTube: yup.string().nullable(),
});

const addressSchema = yup.object({
  city: yup
    .string()
    .label('City')
    .required()
    .defined()
    .transform(value => value?.trim()),
  line1: yup
    .string()
    .label('Line 1')
    .required()
    .defined()
    .transform(value => value?.trim()),
  line2: yup
    .string()
    .nullable()
    .transform(value => value?.trim()),
  state: yup
    .string()
    .label('State')
    .required()
    .defined()
    .transform(value => value?.trim()),
  zipcode: yup
    .string()
    .label('Zipcode')
    .required()
    .defined()
    .transform(value => value?.trim()),
});

export const agencySchema = yup.object({
  legalName: yup
    .string()
    .label('Legal Name')
    .max(200)
    .required()
    .transform(value => value?.trim()),
  displayName: yup
    .string()
    .label('Display Name')
    .max(440)
    .required()
    .transform(value => value?.trim()),
  description: yup.string().label('Description').required(),
  information: yup.string().label('Information').max(350).required(),
  isDonor: yup.bool(),
  isFederatedFund: yup.bool(),
  matchingGrant: grantSchema.nullable(),
  mailingAddress: addressSchema,
  social: socialSchema,
  andarId: yup.string().nullable(),
  bookId: yup.string().nullable(),
  taxId: yup.string().max(200).required(),
  vanityUrl: yup
    .string()
    .label('Vanity URL')
    .max(100)
    .matches(/^[-\w]*$/, 'Vanity URL can only contain letters, numbers, hyphens, and underscores.')
    .nullable()
    .transform(value => value?.trim()),
  websiteUrl: yup.string().max(500).url().nullable(),
  primaryContact: contactSchema,
  secondaryContact: contactSchema2
    .nullable()
    .transform(value =>
      value.emailAddress && value.phoneNumber && value.name.first && value.name.last ? value : null
    ),
  primaryCategory: yup.string().label('Primary Category').required(),
  secondaryCategory: yup.string().nullable(),
  internalComments: yup.string().label('Internal Comments').nullable(),
  eftReceived: yup.bool(),
});
