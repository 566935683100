import { useAuthentication } from '../../auth';

export function useCurrentUserRoles() {
  const user = useAuthentication();
  return user.authenticated ? user.groups : null;
}

export function useIsAdmin() {
  const roles = useCurrentUserRoles();
  return roles != null && (roles.includes('SystemAdmin') || roles.includes('Developer'));
}

export function useIsAgencyAdmin() {
  const roles = useCurrentUserRoles();
  return roles != null && (roles.includes('AgencyAdmin') || roles.includes('Developer'));
}

export function useIsAuthenticated() {
  const user = useAuthentication();
  return user.authenticated;
}
