import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { NavigationItem, NavigationItemType } from '../../api/content';
import { GenericContent } from './GenericContent';
import { FallbackRedirect } from './FallbackRedirect';

interface Props {
  item: NavigationItem;
}

export const NavigationRoute: React.FC<Props> = ({ item }) => {
  const items = useMemo(() => item?.items.filter(x => x.enabled) ?? [], [item]);
  const redirectItem = useMemo(
    () => items.find(x => x.type !== NavigationItemType.External),
    [items]
  );

  return (
    <Routes>
      {item.type === NavigationItemType.Internal && (
        <Route index element={<GenericContent item={item} />} />
      )}
      {item.type === NavigationItemType.External && (
        <Route
          index
          Component={() => {
            window.location.replace(item.externalPath!);
            return null;
          }}
        />
      )}
      {items.map(x => (
        <Route
          key={x.id}
          path={`${x.path?.split('/').at(-1)}/*`}
          element={<NavigationRoute item={x} />}
        />
      ))}
      <Route path="*" element={<FallbackRedirect path={redirectItem?.path} />} />
    </Routes>
  );
};
