import { Dispatch, useEffect } from 'react';
import {
  AuthenticationDetails,
  CognitoUser,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js';

import { Action, ActionType, useAuthFlow } from './useAuthFlow';
import { authService } from './AuthService';
import { useAuthentication } from './AuthenticationContext';

export enum SignInFlowStep {
  SignIn = 'SignIn',
  NewPassword = 'NewPassword',
  Authenticated = 'Authenticated',
}

type SignInStep = SignIn | NewPassword | Authenticated;

export function useSignInFlow() {
  const auth = useAuthentication();

  const [state, dispatch] = useAuthFlow<SignInStep>({
    step: SignInFlowStep.SignIn,
    email: auth.email,
    onSignIn: (email, password) => {
      dispatch({ type: ActionType.Pending });

      const user = authService.createUser(email);

      const credentials = new AuthenticationDetails({ Username: email, Password: password });
      user.authenticateUser(credentials, authCallbacks(user, dispatch));
    },
  });

  useEffect(() => {
    if (!state.user || !state.session || !state.session.isValid()) return;

    const attrs = state.session.getAccessToken().decodePayload();
    auth.update({
      authenticated: true,
      user: state.user,
      email: authService.getEmail(state.session),
      groups: state.step === SignInFlowStep.Authenticated ? state.groups : [],
      userId: attrs['sub'],
    });
    // eslint-disable-next-line
  }, [state]);

  return state;
}

interface SignIn {
  step: SignInFlowStep.SignIn;
  email?: string;
  onSignIn: (email: string, password: string) => void;
}

interface NewPassword {
  step: SignInFlowStep.NewPassword;
  onNewPassword: (password: string) => void;
}

interface Authenticated {
  step: SignInFlowStep.Authenticated;
  groups: string[];
}

function newPasswordStep(user: CognitoUser, dispatch: Dispatch<Action<SignInStep>>): NewPassword {
  return {
    step: SignInFlowStep.NewPassword,
    onNewPassword: password => {
      dispatch({ type: ActionType.Pending });

      user.completeNewPasswordChallenge(password, {}, authCallbacks(user, dispatch));
    },
  };
}

function authCallbacks(
  user: CognitoUser,
  dispatch: Dispatch<Action<SignInStep>>
): IAuthenticationCallback {
  return {
    onSuccess: session => {
      dispatch({
        type: ActionType.Next,
        state: {
          step: SignInFlowStep.Authenticated,
          groups: authService.getUserGroups(session),
          user,
          session,
        },
      });
    },
    newPasswordRequired: () => {
      dispatch({
        type: ActionType.Next,
        state: {
          ...newPasswordStep(user, dispatch),
          user,
        },
      });
    },
    onFailure: error => dispatch({ type: ActionType.Error, error }),
  };
}
