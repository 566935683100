import React, { useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import { Stack } from '@mui/material';

import { GlobalSearchResultCard } from './GlobalSearchResultCard';
import { SEO } from '../../api/content';

type SearchResultModel = {
  id: string;
  url: string;
  title: string;
  description?: string;
  content: string[];
  seo?: SEO;
};

export const GlobalSearchGrid: React.FC = () => {
  const { items, isLastPage, showMore } = useInfiniteHits<SearchResultModel>();
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  return (
    <Stack spacing={3} p={4}>
      {items.map(hit => (
        <GlobalSearchResultCard
          key={hit.objectID}
          title={hit.title}
          link={hit.url}
          description={hit.description ?? hit.seo?.metaDescription ?? hit.content[0]}
        />
      ))}
      <div ref={sentinelRef} aria-hidden="true" />
    </Stack>
  );
};
