import { useMemo } from 'react';
import { ApiClient, EntityCollection } from '../api/content';
import { createPageQuery, unwrap } from './factory';
import { ArticleTopic } from './useNewsArticlesQuery';

function getNewsArticleTopics() {
  return ApiClient.get<EntityCollection<ArticleTopic>>('/article-topics', {
    params: {
      populate: ['newsArticles'],
    },
  });
}

export function getNewsArticleTopicsQuery() {
  return createPageQuery({
    queryKey: ['articleTopics'],
    queryFn: unwrap(() => getNewsArticleTopics()),
  });
}

export function useNewsArticleTopicsQuery() {
  const { useQuery } = useMemo(() => getNewsArticleTopicsQuery(), []);

  return useQuery();
}
