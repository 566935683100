export default function downloadFile(fileName: string, fetchData: Promise<Blob>) {
  fetchData.then(data => {
    const url = URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  });
}
