import { FormProvider, useForm } from 'react-hook-form';
import {
  DonationFilterForm,
  agencyColumns,
  filterFields,
  sortFieldMap,
} from './DonationsListComponents';
import { useNextEvent } from '../../shared/events';
import { useDebouncedValue } from '../../shared/utils';
import {
  DonationsListAgencyDonationsDonationList,
  ModelsFundraisingEventModel as FundraisingEvent,
  ModelsOrderByDirection,
  exportAgencyDonations,
  useListAgencyDonations,
} from '../../api/agency-api';
import { useCallback, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridToolbar } from '../../shared/components';
import downloadFile from '../../shared/utils/downloadFile';
import { TextInput } from '../../shared/forms';
import { MenuItem } from '@mui/material';

interface AgencyDonationsListProps {
  agencyId: string;
  events: FundraisingEvent[];
}
export const AgencyDonationsList: React.FC<AgencyDonationsListProps> = ({
  agencyId,
  events,
}: AgencyDonationsListProps) => {
  const nextEvent = useNextEvent();
  const form = useForm<DonationFilterForm>({
    defaultValues: {
      eventId: nextEvent?.donationAccessEnabled ? nextEvent?.id : events[events.length - 1]?.id,
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'donationDate', sort: 'asc' }],
    },
  });

  const { search, eventId, minDonationAmt, maxDonationAmt, pagination, orderBy } = form.watch();

  const params = useDebouncedValue(
    () => ({
      search,
      eventId,
      minDonationAmt,
      maxDonationAmt,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [search, eventId, minDonationAmt, maxDonationAmt, pagination, orderBy]
  );

  const { data: donations, isPending } = useListAgencyDonations(eventId, agencyId, params);

  const [gridData, setGridData] = useState<DonationsListAgencyDonationsDonationList>();

  // only update the gridData after the server responds so that the list doesn't flicker.
  useEffect(() => {
    if (!isPending) setGridData(donations);
  }, [donations, isPending]);

  const handleExport = useCallback(() => {
    downloadFile('Donation-Export.csv', exportAgencyDonations(eventId, agencyId, params));
  }, [eventId, agencyId, params]);
  return (
    <FormProvider {...form}>
      {gridData && (
        <DataGrid
          autoHeight
          rows={gridData?.items ?? []}
          rowCount={gridData?.totalCount}
          columns={agencyColumns}
          pagination
          paginationMode="server"
          paginationModel={pagination}
          pageSizeOptions={[5, 10, 25, 50]}
          onPaginationModelChange={x => form.setValue('pagination', x)}
          sortingMode="server"
          sortModel={orderBy}
          onSortModelChange={x => form.setValue('orderBy', x)}
          getRowId={row => row.id!}
          sx={{ width: '100%', maxWidth: '100%', border: '0', display: 'grid' }}
          slots={{
            toolbar: DataGridToolbar,
          }}
          slotProps={{
            toolbar: {
              headerFilterInputs: (
                <TextInput
                  select
                  name="eventId"
                  label="Event"
                  variant="outlined"
                  sx={{
                    marginBottom: '0',
                    marginLeft: '.25rem',
                  }}
                >
                  {events.map(x => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </TextInput>
              ),
              filterFields: filterFields,
              modelName: 'Donations',
              allColumns: true,
              onExport: handleExport,
            },
          }}
        />
      )}
    </FormProvider>
  );
};
