import { Routes, Route, Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useIsAdmin } from './shared/hooks/authFunctions';

import { withBoundary } from './shared/withBoundary';
import { Root } from './shared/layout/Root';
import { HomePage } from './home/HomePage';
import {
  prefetchNavigation,
  prefetchRedirects,
  prefetchRTDHomePage,
  prefetchPageFooterSocials,
  prefetchDonateModal,
} from './queries';
import { AdminRoutes } from './admin/AdminRoutes';
import { APP_CONFIG } from './shared/config';
import SearchPage from './home/SearchPage';
import { DynamicTopLevelRoutes } from './shared/routing';

export const StaticTopLevelPaths: ReadonlySet<string> = new Set<string>([
  'search',
  'account',
  'agency',
  'who-we-are',
  'what-we-do',
  'roc-the-day',
  'admin',
]);

/**
 * Prefetch pages that are likely to be visited first.
 */
prefetchNavigation();
prefetchRedirects();
prefetchDonateModal();
prefetchRTDHomePage();
prefetchPageFooterSocials();

const marketingRoutes = APP_CONFIG.RTDOnly
  ? [{ index: true, Component: withBoundary(RTDOnlyRoutes) }]
  : [
      { index: true, Component: withBoundary(HomePage) },
      { path: 'search', Component: withBoundary(SearchPage) },
      { path: 'who-we-are/*', lazy: () => import('./about/AboutRoutes') },
      { path: 'what-we-do/*', lazy: () => import('./marketing/MarketingRoutes') },
    ];
/**
 * Top-level data router for the application.
 *  - Includes the root layout
 *  - Supports scroll restoration on navigate (see Root)
 *  - Enables router-aware error boundaries and suspense fallbacks (see withBoundary)
 */
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      ...marketingRoutes,
      { path: 'account/*', lazy: () => import('./account/AccountRoutes') },
      { path: 'agency/*', lazy: () => import('./agency/AgencyRoutes') },
      { path: 'roc-the-day/*', lazy: () => import('./rtd/RTDRoutes') },
      { path: '*', Component: withBoundary(ProtectedRoutes) },
    ],
  },
]);

function ProtectedRoutes() {
  const isAdmin = useIsAdmin();
  return (
    <Routes>
      {isAdmin && <Route path="admin/*" element={<AdminRoutes />} />}
      <Route path="*" element={<DynamicTopLevelRoutes />} />
    </Routes>
  );
}

function RTDOnlyRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="/roc-the-day" />} />
    </Routes>
  );
}

export function AppRoutes() {
  return <RouterProvider router={router} />;
}
