import { ApiClient, DynamicComponentTypes, EntityItem } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface SignInPage {
  contentComponents?: Array<DynamicComponentTypes>;
}

function getRocLoginPage() {
  return ApiClient.get<EntityItem<SignInPage>>('/roc-the-day-login', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: RocLoginPageQuery,
  useQuery: useRocLoginPageQuery,
  prefetch: prefetchRocLoginPage,
} = createPageQuery({
  queryKey: ['roc-the-day-login'],
  queryFn: unwrap(getRocLoginPage),
});
