import { ApiClient, EntityItem, GrapesContent, SEO } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface DonationSuccessPage {
  content?: GrapesContent;
  SEO?: SEO;
}

function getDonationSuccessPage() {
  return ApiClient.get<EntityItem<DonationSuccessPage>>('/donation-success', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: DonationSuccessPageQuery,
  useQuery: useDonationSuccessQuery,
  prefetch: prefetchDonationSuccessPage,
} = createPageQuery({
  queryKey: ['donation-success'],
  queryFn: unwrap(getDonationSuccessPage),
});
