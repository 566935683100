import { Box, Typography, Stack, Divider, TypographyVariant } from '@mui/material';
import { ContentSection } from './ContentSection';
import { CountUpComponent } from './CountUp';
import React from 'react';
import { DynamicStatSectionComponent, RichTextContent, StatComponent } from '../../api/content';
import { RichText } from './RichText';

export interface StatsSectionProps {
  title: React.ReactNode;
  sectionCopy: RichTextContent;
  backgroundColor: DynamicStatSectionComponent['backgroundColor'];
  stats: Array<StatComponent>;
  textHorizontalPosition?: 'left' | 'center' | 'right';
}

const colorMapping: Record<StatsSectionProps['backgroundColor'], [string, string]> = {
  // map the component color to a tuple of [background color, font color]
  blue: ['primary.main', 'common.white'],
  white: ['common.white', 'primary.main'],
  grey: ['grey.400', 'primary.main'],
};

export function StatsSection({
  title,
  sectionCopy,
  backgroundColor,
  stats,
  textHorizontalPosition = 'center',
}: StatsSectionProps) {
  const [bgColor, fontColor] = colorMapping[backgroundColor];

  return (
    <ContentSection bannerBGColor={bgColor} align="center" justify="center" padding={3}>
      <Box p={{ xs: '0', md: '3rem' }} color={fontColor} textAlign={textHorizontalPosition}>
        <Typography variant="h3" color={fontColor}>
          {title}
        </Typography>
        <RichText content={sectionCopy} />
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="space-between"
          spacing={4}
          py={5}
          divider={
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                borderColor: fontColor,
              }}
            />
          }
        >
          {stats.map((stat, index) => (
            <Box key={index} display="flex" flexDirection="column" sx={{ flex: '1 1 0px' }}>
              <CountUpComponent
                textVariant={stat.statVariant as TypographyVariant}
                end={stat.stat}
                decimals={stat.statDecimals ?? undefined}
                prefix={stat.statPrefix ?? undefined}
                suffix={stat.statSuffix ?? undefined}
              />
              <RichText content={stat.statCopy} />
            </Box>
          ))}
        </Stack>
      </Box>
    </ContentSection>
  );
}
