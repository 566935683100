import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
} from 'react-share';

type Props = {
  url: string;
};

export const ShareToSocial: React.FC<Props> = ({ url }) => {
  return (
    <Stack direction="row" py={3} alignItems="center" spacing={2}>
      <Typography>Share:</Typography>
      <EmailShareButton url={url}>
        <EmailIcon bgStyle={{ backgroundColor: 'primary.main' }} size={32} round />
      </EmailShareButton>
      <TwitterShareButton url={url}>
        <XIcon bgStyle={{ backgroundColor: 'primary.main' }} size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon bgStyle={{ backgroundColor: 'primary.main' }} size={32} round />
      </LinkedinShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon bgStyle={{ backgroundColor: 'primary.main' }} size={32} round />
      </FacebookShareButton>
    </Stack>
  );
};
