import { ApiClient, EntityItem, Page } from '../api/content';
import { createPageQuery, unwrap } from './factory';

interface WhatWeDoPage extends Page {}

function getWhatWeDo() {
  return ApiClient.get<EntityItem<WhatWeDoPage>>('/what-we-do', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: WhatWeDoQuery,
  useQuery: useWhatWeDoQuery,
  prefetch: prefetchWhatWeDo,
} = createPageQuery({
  queryKey: ['what-we-do'],
  queryFn: unwrap(getWhatWeDo),
});
