import { File } from '../../api/content';

export type ImageSize = 'large' | 'medium' | 'small' | 'thumbnail';

export function formatMediaUrl(image: File, size?: ImageSize) {
  const url = size && image.formats?.[size] ? image.formats[size].url : image.url;

  switch (image.provider) {
    case 'local':
      return `${process.env.REACT_APP_STRAPI_HOST}${url}`;
    case 'aws-s3':
    default:
      return url;
  }
}
