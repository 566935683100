import { Stack } from '@mui/material';
import { useHomePageQuery } from '../queries';
import { Banner, DynamicZone } from '../shared/components';

export const HomePage: React.FC = () => {
  const { data: page } = useHomePageQuery();
  return (
    <Stack>
      {page.attributes.bannerComponent && (
        <Banner bannerComponent={page.attributes.bannerComponent} />
      )}
      {page.attributes.contentComponents && (
        <DynamicZone key="home" components={page.attributes.contentComponents} spacing={0} />
      )}
    </Stack>
  );
};
