import React, { ReactNode, useMemo, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';

import { Link } from './Link';

import './flipCard.css';
import { formatMediaUrl, isFile } from '../utils';
import { File } from '../../api/content';

type Props = {
  flipImage?: File;
  frontLogo?: File;
  title?: string;
  location?: string;
  link: string;
  children?: ReactNode;
};

const Logo = styled('img')(({ theme }) => ({
  display: 'inline-block',
  height: 'auto',
  width: '150px',
}));

const Image = styled('img')(({ theme }) => ({
  display: 'inline-block',
  height: 'auto',
  width: '100%',
}));

export const FlipCard: React.FC<Props> = ({
  flipImage,
  frontLogo,
  title,
  location,
  link,
  children,
}) => {
  const [flip, setFlip] = useState(false);
  const formattedFlipImage = useMemo(() => {
    if (!isFile(flipImage)) {
      return `URL(${flipImage})`;
    }
    return formatMediaUrl(flipImage);
  }, [flipImage]);
  const formattedFrontLogo = useMemo(() => {
    if (!isFile(frontLogo)) {
      return `URL(${frontLogo})`;
    }
    return formatMediaUrl(frontLogo);
  }, [frontLogo]);
  return (
    <Link to={link} onMouseEnter={() => setFlip(!flip)} onMouseLeave={() => setFlip(!flip)}>
      <Box className={`flipCard ${flip ? 'flip' : ''}`} sx={{ height: '100%' }}>
        <Image
          src={formattedFlipImage}
          alt={title}
          sx={{
            transform: 'rotateY(180deg)',
            display: { xs: 'none', md: 'flex' },
          }}
        />
        <Box
          className="front"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: 'grey.200',
            position: { xs: 'inherit', md: 'absolute' },
            height: '100%',
          }}
        >
          <Box sx={{ padding: { xl: '2rem', md: '1.5rem', xs: '1rem' } }}>
            {frontLogo && (
              <Logo
                src={formattedFrontLogo}
                alt={title}
                sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            )}

            {title && (
              <Typography color="primary.main" textAlign="center" variant="h4">
                {title}
              </Typography>
            )}
            {location && (
              <Typography color="primary.main" textAlign="center">
                {location}
              </Typography>
            )}
            {children}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
