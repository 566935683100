import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { ModelsFundraisingEventModel as FundraisingEvent } from '../api/agency-api';
import { APP_CONFIG } from '../shared/config';
import { CartState } from './CartContext';
import { getEventDayRange } from '../shared/events';

export type PaymentAuth = StripePayment | PaypalPayment;

export interface ICheckoutService {
  createDonation(
    cart: CartState,
    event: FundraisingEvent | null,
    payment: PaymentAuth
  ): Promise<AxiosResponse<any, any>>;

  getPaypalBillingAgreementToken(): Promise<any>;
  getPaypalBillingAgreement(token: string): Promise<any>;
}

class CheckoutService implements ICheckoutService {
  private static readonly apiClient = axios.create({
    baseURL: APP_CONFIG.Donately.BaseUrl,
  });

  async createDonation(cart: CartState, event: FundraisingEvent | null, payment: PaymentAuth) {
    const eventRange = getEventDayRange(event);
    if (!eventRange || !dayjs.tz().isBetween(eventRange.start, eventRange.end))
      throw new Error('Cannot submit donation outside of event day');

    let donation_type = '';
    if (isStripePayment(payment)) donation_type = 'cc';
    else if (isPaypalPayment(payment)) donation_type = 'paypal';

    return await CheckoutService.apiClient.post(
      '/donations',
      {
        campaign_id: event?.donatelyCampaignId,
        currency: 'USD',
        donation_type,

        // We send our own itemized receipt.
        dont_send_receipt_email: true,
        recurring: false,

        first_name: cart.billingInfo.firstName,
        last_name: cart.billingInfo.lastName,
        email: cart.billingInfo.email,
        phone_number: cart.billingInfo.phoneNumber,
        anonymous: cart.billingInfo.anonymous,

        street_address: cart.billingInfo.addressLine1,
        street_address_2: cart.billingInfo.addressLine2,
        city: cart.billingInfo.city,
        state: cart.billingInfo.state,
        zip_code: cart.billingInfo.zip,
        country: 'US',

        meta_data: JSON.stringify(this.getMetadata(cart)),
        payment_auth: JSON.stringify(payment),
      },
      {
        params: {
          account_id: APP_CONFIG.Donately.AccountId,
          donation_type,
          amount_in_cents: cart.total,
        },
      }
    );
  }

  async getPaypalBillingAgreementToken(): Promise<any> {
    const response = await CheckoutService.apiClient.get(
      '/partners/paypal/create_billing_agreement_token',
      {
        params: {
          account_id: APP_CONFIG.Donately.AccountId,
          origin: window.top?.location.href ?? window.location.href,
        },
      }
    );

    return response.data.data;
  }

  async getPaypalBillingAgreement(token: string): Promise<any> {
    const response = await CheckoutService.apiClient.get(
      '/partners/paypal/create_billing_agreement',
      {
        params: {
          account_id: APP_CONFIG.Donately.AccountId,
          token_id: token,
        },
      }
    );

    return response.data;
  }

  private getMetadata(cart: CartState): Record<string, any> {
    const metadata: Record<string, any> = {
      environment: APP_CONFIG.Environment,
    };

    for (let i = 0; i < cart.items.length; i++) {
      const item = JSON.stringify({
        agencyId: cart.items[i].agencyId,
        amount: cart.items[i].amount,
        dedication: cart.items[i].dedication,
      });
      metadata[`items-${i}`] = item;
    }

    if (cart.raffleAgencyId) metadata.raffleAgencyId = cart.raffleAgencyId;

    if (cart.billingInfo.recognitionName)
      metadata.recognitionName = cart.billingInfo.recognitionName;

    if (cart.coverProcessingFee) {
      metadata['base-amount'] = cart.baseAmount;
      metadata['donor-pays-fees'] = cart.fees;
    }

    return metadata;
  }
}

export const checkoutService: ICheckoutService = new CheckoutService();

export interface StripePayment {
  stripe_token: string;
}

export interface PaypalPayment {
  billing_agreement_id: string;
  payer_info: {
    email: string;
    first_name: string;
    last_name: string;
    payer_id: string;
    tenant: string;
  };
}

function isStripePayment(payment: PaymentAuth): payment is StripePayment {
  return !!(payment as StripePayment)?.stripe_token;
}

function isPaypalPayment(payment: PaymentAuth): payment is PaypalPayment {
  return !!(payment as PaypalPayment)?.billing_agreement_id;
}
