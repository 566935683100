import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

import { APP_CONFIG } from '../shared/config';

export interface CurrentSession {
  user: CognitoUser;
  session?: CognitoUserSession;
}

export interface IAuthService {
  resumeSession(): Promise<CurrentSession | null>;
  getCurrentUser(): CognitoUser | null;
  createUser(email: string): CognitoUser;
  getEmail(session: CognitoUserSession): string;
  getUserGroups(session: CognitoUserSession): string[];
  getJwtToken(): Promise<string | undefined>;
}

class AuthService implements IAuthService {
  private static readonly userPool = new CognitoUserPool(APP_CONFIG.Cognito);

  resumeSession(): Promise<CurrentSession | null> {
    return new Promise((resolve, reject) => {
      const user = AuthService.userPool.getCurrentUser();
      if (user == null) return resolve(null);

      user.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error != null) return reject(error);

        if (session.isValid()) return resolve({ user, session });

        resolve({ user });
      });
    });
  }

  getCurrentUser(): CognitoUser | null {
    return AuthService.userPool.getCurrentUser();
  }

  createUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: AuthService.userPool,
    });
  }

  getEmail(session: CognitoUserSession): string {
    return session.getIdToken().decodePayload()['email'];
  }

  getUserGroups(session: CognitoUserSession): string[] {
    return session.getAccessToken().decodePayload()['cognito:groups'];
  }

  getJwtToken(): Promise<string | undefined> {
    return new Promise(resolve => {
      const user = AuthService.userPool.getCurrentUser();
      if (user == null) return resolve(undefined);

      user.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error != null || !session.isValid()) return resolve(undefined);

        resolve(session.getAccessToken().getJwtToken());
      });
    });
  }
}

export const authService: IAuthService = new AuthService();
