import { DependencyList, useEffect, useState } from 'react';

export function useDebouncedValue<T = unknown>(
  factory: () => T,
  timeout: number,
  deps: DependencyList
) {
  const [value, setValue] = useState<T>(factory);

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(factory()), timeout);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, deps);

  return value;
}
