import { useMemo } from 'react';
import { ApiClient, EntityCollection, RelatedFile } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface BoardMember {
  firstName: string;
  lastName: string;
  jobTitle: string;
  organization: string;
  photo: RelatedFile;
}

function getBoardMembers(query: string) {
  return ApiClient.get<EntityCollection<BoardMember>>('/board-members', {
    params: {
      filters: {
        $or: {
          0: {
            firstName: {
              $containsi: query,
            },
          },
          1: {
            lastName: {
              $containsi: query,
            },
          },
        },
      },
      sort: {
        lastName: 'asc',
      },
      populate: {
        photo: '*',
      },
    },
  });
}

export function getBoardMembersQuery(query: string) {
  return createPageQuery({
    queryKey: ['boardMember', query],
    queryFn: unwrap(() => getBoardMembers(query)),
  });
}

export function useBoardMembersQuery(query: string) {
  const { useQuery } = useMemo(() => getBoardMembersQuery(query), [query]);

  return useQuery();
}
