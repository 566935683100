import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import {
  AuthState,
  AuthStateHandle,
  AuthenticationContext,
  authService,
  useResumeSession,
} from '../auth';

import { setJwtToken, clearJwtToken } from '../api/agency-api/client';

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ authenticated: false });
  const contextValue = useMemo<AuthStateHandle>(
    () => ({ ...authState, update: setAuthState }),
    [authState]
  );

  const loading = useResumeSession(contextValue);

  useEffect(() => {
    if (authState.authenticated) setJwtToken(authService.getJwtToken);
    else clearJwtToken();
  }, [authState]);

  if (loading) return null;

  return (
    <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>
  );
};
