import { ApiClient, BannerComponent, DynamicComponentTypes, EntityItem, SEO } from '../api/content';
import { createPageQuery, unwrap } from './factory';

export interface GlobalSearchPage {
  bannerComponent: BannerComponent;
  contentComponents: Array<DynamicComponentTypes>;
  SEO?: SEO;
}

function getGlobalSearchPage() {
  return ApiClient.get<EntityItem<GlobalSearchPage>>('/global-search', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: GlobalSearchPageQuery,
  useQuery: useGlobalSearchPageQuery,
  prefetch: prefetchGlobalSearchPage,
} = createPageQuery({
  queryKey: ['global-search'],
  queryFn: unwrap(getGlobalSearchPage),
});
