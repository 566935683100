import React, { useMemo } from 'react';
import { Box, Typography, styled } from '@mui/material';

import { BannerComponent } from '../../api/content';
import { isFile, formatMediaUrl, getPosition } from '../utils';
import { RichText } from './RichText';
import { Image } from './Image';

type Props = {
  children?: React.ReactNode;
  height?: string;
  tabletHeight?: string;
  mobileHeight?: string;
  padding?: number;
  bannerBGColor?: string;
  bannerComponent: BannerComponent;
};

const BannerRichText = styled(RichText)(({ theme }) => ({
  color: theme.palette.common.white,
  '& p': {
    fontSize: '1.25rem',
  },
  ...theme.typography,
}));

export const Banner: React.FC<Props> = ({
  children,
  height,
  tabletHeight,
  mobileHeight,
  padding,
  bannerBGColor,
  bannerComponent,
}) => {
  const { title, bannerLogo, bannerText } = bannerComponent;
  const pageBanner = bannerComponent.pageBanner.data?.attributes;
  const tabletBanner = bannerComponent.tabletBanner.data?.attributes;
  const mobileBanner = bannerComponent.mobileBanner.data?.attributes;

  const backgroundImage = useMemo(() => {
    const bannerImageURL = isFile(pageBanner) ? formatMediaUrl(pageBanner) : pageBanner;
    const mobileBannerImageURL = isFile(mobileBanner)
      ? formatMediaUrl(mobileBanner, 'small')
      : mobileBanner ?? bannerImageURL;
    const tabletBannerImageURL = isFile(tabletBanner)
      ? formatMediaUrl(tabletBanner, 'medium')
      : tabletBanner ?? bannerImageURL;
    return {
      xs: `URL(${mobileBannerImageURL})`,
      sm: `URL(${tabletBannerImageURL})`,
      md: `URL(${bannerImageURL})`,
    };
  }, [pageBanner, mobileBanner, tabletBanner]);

  const position = useMemo(() => {
    if (bannerLogo) {
      return getPosition(bannerLogo);
    }
    return getPosition(bannerText);
  }, [bannerLogo, bannerText]);
  const bannerHeight = useMemo(() => {
    if (!pageBanner && !tabletBanner && !mobileBanner) return undefined;

    if (mobileHeight && tabletHeight && height)
      return { xs: mobileHeight, sm: tabletHeight, lg: height };

    if (!title) return { xs: '100vh', sm: '100vh', lg: '100vh' };
    return { xs: '50vh', sm: '75vh', lg: '80vh' };
  }, [pageBanner, height, mobileBanner, mobileHeight, tabletBanner, tabletHeight, title]);
  return (
    <>
      {title && (
        <Box
          display="flex"
          alignItems={'end'}
          justifyContent={'center'}
          sx={{
            padding: {
              xs: '5.5rem 1rem 1rem 1rem',
              sm: '5.5rem 1rem 1rem 1rem',
              md: '10.5rem 1rem 1rem 1rem',
              lg: '11.5rem 1rem 1rem 1rem',
            },
            width: '100%',
          }}
        >
          <Typography variant="h1" sx={{ color: 'primary.main' }}>
            {title}
          </Typography>
        </Box>
      )}
      {pageBanner && (
        <Box
          display="flex"
          alignItems={position?.vertical}
          justifyContent={position?.horizontal}
          p={padding}
          sx={{
            backgroundImage: backgroundImage,
            backgroundColor: bannerBGColor,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: bannerHeight,
            padding: {
              xs: '5.5rem 1rem 1rem 1rem',
              md: '10rem 1rem 1rem 1rem',
            },
            width: '100%',
          }}
        >
          {bannerLogo?.image.data && (
            <Image
              src={bannerLogo.image.data.attributes}
              sx={{
                display: 'inline-block',
                height: 'auto',
                maxWidth: {
                  xs: '250px',
                  sm: '280px',
                  lg: '400px',
                  xl: '450px',
                },
                width: '100%',
              }}
            />
          )}
          {bannerText && (
            <Box p={{ xs: '0', md: '3rem' }} sx={{ maxWidth: '800px', marginTop: '6rem' }}>
              <BannerRichText content={bannerText.text} />
            </Box>
          )}
          {children}
        </Box>
      )}
    </>
  );
};
