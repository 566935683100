import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faPhone,
  faBars,
  faBrain,
  faPalette,
  faLightbulb,
  faPeopleGroup,
  faCode,
  faPaperPlane,
  faSquareCheck,
  faForward,
  faHandshake,
  faColumns,
  faList,
  faTasks,
  faSearch,
  faRocket,
  faShieldAlt,
  faLeftLong,
  faRightLong,
  faUpLong,
  faDownLong,
  faXmark,
  faTriangleExclamation,
  faFilter,
  faChevronDown,
  faChevronUp,
  faSquareFull,
  faCheck,
  faGlobe,
  faDownload,
  faPlus,
  faChevronCircleDown,
  faTable,
  faSignOut,
  faRotate,
  faHandHoldingHeart,
  faFile,
  faClock,
  faLaptop,
  faEnvelopeCircleCheck,
  faChevronLeft,
  faEdit,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faXTwitter,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { faCartShopping } from '@fortawesome/pro-regular-svg-icons';
import { faReceipt } from '@fortawesome/sharp-solid-svg-icons';

export const icons = {
  calendar: faCalendarAlt as IconProp,
  phone: faPhone as IconProp,
  menu: faBars as IconProp,
  brain: faBrain as IconProp,
  portfolio: faPalette as IconProp,
  insights: faLightbulb as IconProp,
  team: faPeopleGroup as IconProp,
  code: faCode as IconProp,
  plane: faPaperPlane as IconProp,
  check: faSquareCheck as IconProp,
  up: faUpLong as IconProp,
  down: faDownLong as IconProp,
  left: faLeftLong as IconProp,
  right: faRightLong as IconProp,
  forward: faForward as IconProp,
  handshake: faHandshake as IconProp,
  columns: faColumns as IconProp,
  list: faList as IconProp,
  tasks: faTasks as IconProp,
  search: faSearch as IconProp,
  rocket: faRocket as IconProp,
  shield: faShieldAlt as IconProp,
  close: faXmark as IconProp,
  caution: faTriangleExclamation as IconProp,
  filter: faFilter as IconProp,
  expand: faChevronDown as IconProp,
  collapse: faChevronUp as IconProp,
  square: faSquareFull as IconProp,
  checkmark: faCheck as IconProp,
  globe: faGlobe as IconProp,
  facebook: faFacebookF as IconProp,
  instagram: faInstagram as IconProp,
  linkedin: faLinkedinIn as IconProp,
  twitter: faTwitter as IconProp,
  youtube: faYoutube as IconProp,
  twitterx: faXTwitter as IconProp,
  download: faDownload as IconProp,
  add: faPlus as IconProp,
  chevronDown: faChevronCircleDown as IconProp,
  table: faTable as IconProp,
  signout: faSignOut as IconProp,
  rotate: faRotate as IconProp,
  donate: faHandHoldingHeart as IconProp,
  file: faFile as IconProp,
  clock: faClock as IconProp,
  laptop: faLaptop as IconProp,
  email: faEnvelopeCircleCheck as IconProp,
  back: faChevronLeft as IconProp,
  tikTok: faTiktok as IconProp,
  edit: faEdit as IconProp,
  remove: faTimes as IconProp,
  cart: faCartShopping as IconProp,
  copyright: faCopyright as IconProp,
  transactions: faReceipt as IconProp,
};
