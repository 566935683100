import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';

import { Link } from '../../components';
import { useNavigationStructure } from '../../routing';

export const FooterMenu: React.FC = () => {
  const nav = useNavigationStructure();

  return (
    <Grid
      container
      py={3}
      sx={{ maxWidth: '1700px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
    >
      {nav.getFooterItems().map(item => (
        <Grid key={item.id} item xs={12} md={4} lg={2} pr={2}>
          <Stack mb={4}>
            {item.footerAttached ? (
              <Link to={item.path} variant="h5" color="common.white">
                {item.title}
              </Link>
            ) : (
              <Typography variant="h5" color="common.white">
                {item.title}
              </Typography>
            )}

            {item.items
              .filter(x => x.enabled && x.footerAttached)
              .map(sub => (
                <Link
                  key={sub.id}
                  to={sub.path}
                  sx={{ fontWeight: '400' }}
                  color="common.white"
                  mb={1.5}
                  variant="body2"
                >
                  {sub.title}
                </Link>
              ))}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};
