/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type DonationsDonationQuerySortProperty =
  (typeof DonationsDonationQuerySortProperty)[keyof typeof DonationsDonationQuerySortProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DonationsDonationQuerySortProperty = {
  Time: 'Time',
  Amount: 'Amount',
} as const;
