/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */

export type FundraisingEventQuerySortProperty =
  (typeof FundraisingEventQuerySortProperty)[keyof typeof FundraisingEventQuerySortProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundraisingEventQuerySortProperty = {
  EventDate: 'EventDate',
  Name: 'Name',
  Id: 'Id',
} as const;
